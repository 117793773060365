import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Divider } from '@fcamna/react-library';
import { useEffect, useState } from 'react';

import useSurveyApi from '../../../apis/useSurveyApi';
import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import useLocalStorageSync from '../../../utils/useLocalStorageSync';
import Btn from '../../atoms/button';
import Checkbox from '../../atoms/checkbox';
import fordProIcons from '../../atoms/icon';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import BtnGroup from '../../molecules/btnGroup';
import styles from './survey.module.scss';

const AEM_PATH = getAEMPath('survey');

const { CheckmarkFilled: Success } = fordProIcons;

const handleReturnHome = (homePath: string) => {
  const link = document.createElement('a');
  link.href = homePath;
  link.target = '_self';
  link.click();
};

const CanadaThankYou = () => {
  const homePath = useClocStore((state: { homePath: string }) => state.homePath);
  return (
    <div
      data-testid="sur-cloc-cust"
      className={styles.section}>
      <Text name="cloc-survey-title" />
      <div className={styles.sectionHeading}>
        <div className={`${styles.headingIcon} ${styles.success}`}>
          <Success size="xl" />
        </div>
        <Text
          name="cloc-survey-para-ca"
          className={styles.field}
        />
      </div>
      <Text name="ca-cloc-survey-success-description" />
      <Btn
        className={styles.returnButton}
        key="successReturnHome"
        name="successReturnHome"
        onClick={() => handleReturnHome(homePath)}
      />
    </div>
  );
};

const ThankYouPage = () => {
  const homePath = useClocStore((state: { homePath: string }) => state.homePath);
  return (
    <>
      <Text
        data-testid={'us-success-title'}
        name="cloc-survey-success-title"
      />
      <Text
        name="cloc-survey-success-description"
        className={styles.description}
      />
      <Btn
        className={styles.returnButton}
        key="successReturnHome"
        name="successReturnHome"
        onClick={() => handleReturnHome(homePath)}
      />
    </>
  );
};

const ErrorPage = (props: { submitError: boolean }) => {
  const { submitError } = props;
  const homePath: string = useClocStore((state: { homePath: string }) => state.homePath);
  const isAuthorView = AuthoringUtils.isInEditor();
  const updateRootPage = useClocStore((state: { updateRootPage: any }) => state.updateRootPage);
  const region = useClocStore((state) => (state as IClocState).region);

  const handlePrevious = () => {
    region === 'us' ? updateRootPage('screener') : updateRootPage('cloc');
  };

  const getSubmitErrorContent = () => {
    const btns = [];

    btns.push(
      <Btn
        key="prev"
        name="prev"
        onClick={() => handlePrevious()}
        directProps={{ icon: 'caretLeft', iconPosition: 'left', size: 'small' }}
      />
    );

    btns.push(
      <Btn
        key="docusing"
        variant="text"
        href={region === 'us' ? process.env.DOCU_SIGN_US : process.env.DOCU_SIGN_CA}
        target="_blank"
        as={'a'}
        rel="noreferrer noopener"
      />
    );
    return (
      <>
        <Text
          name="cloc-submit-failure-description"
          className={styles.description}
        />
        <div className={styles.docusignWrapper}>
          <BtnGroup
            className={styles.nav}
            btns={btns}
          />
        </div>
      </>
    );
  };

  const getSurveyErrorContent = (homePath: string) => {
    return (
      <>
        <Text
          name="cloc-survey-failure-description"
          className={styles.description}
        />
        <Btn
          key="errorReturnHome"
          name="errorReturnHome"
          className={styles.returnButton}
          onClick={() => handleReturnHome(homePath)}
        />
      </>
    );
  };

  return (
    <>
      <Text name="cloc-survey-failure-title" />
      {submitError ? getSubmitErrorContent() : getSurveyErrorContent(homePath)}
      {isAuthorView && (
        <>
          {getSubmitErrorContent()} {getSurveyErrorContent(homePath)}
        </>
      )}
    </>
  );
};

const Survey = () => {
  const updateRootPage = useClocStore((state) => state.updateRootPage);
  const [offeringsRequested, setOfferingsRequested] = useState<Array<string>>([]);
  const [permissionsRequested, setPermissionsRequested] = useState<any>({
    required: false,
    promotional: false,
    insurance: false
  });
  const [showInsurance, setShowInsurance] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const isAuthorView = AuthoringUtils.isInEditor();
  const { fireAPI, apiState } = useSurveyApi();
  const homePath = useClocStore((state) => state.homePath);
  const [permissionsRequestedValues, setPermissionsRequestedValues] = useState<Record<string, boolean>>({});
  const region = useClocStore((state) => (state as IClocState).region);
  const signAndSubmit = useClocStore((state) => (state as IClocState).clocFormData.signAndSubmit);
  const appType = useClocStore((state) => (state as IClocState).appType);
  const isCreditApp = appType === 'credit';
  const { resetLS } = useLocalStorageSync({ skipSub: true });
  const submitError = useClocStore((state) => state.submitError);

  console.log('Submit Error: ', submitError);
  console.log('api state', apiState);

  const offeringCheckHandler = ({ value }: IDynamicObj) => {
    const offerings = value as Record<string, string | boolean>[];
    const filteredOfferings: Array<string> = offerings.reduce((accumulator: Array<string>, offering: IDynamicObj) => {
      if (offering.checked) accumulator.push(String(offering.value));
      return accumulator;
    }, []);
    setOfferingsRequested(filteredOfferings);
    const prevPerm = { ...permissionsRequested };
    prevPerm.required = filteredOfferings.length > 0;
    setPermissionsRequestedValues({ ...permissionsRequestedValues, 0: filteredOfferings.length > 0 });

    if (filteredOfferings.includes('FORD_PRO_INSURE')) {
      setShowInsurance(true);
    } else {
      setShowInsurance(false);
      prevPerm.insurance = false;
    }
    setPermissionsRequested({ ...prevPerm });
  };

  const validate = () => {
    let isValid = false;
    if (offeringsRequested.includes('FORD_PRO_INSURE')) {
      isValid = permissionsRequested.required && permissionsRequested.promotional && permissionsRequested.insurance;
    } else {
      isValid = permissionsRequested.required && permissionsRequested.promotional;
    }
    setEnableSubmit(isValid);
  };

  const nonInsuranceCheckHandler = ({ value }: IDynamicObj) => {
    const permissions = value as Record<string, string | boolean>[];
    let permissionRequestedRecord = {};
    const filteredPermissions: Array<string> = permissions.reduce((accumulator: Array<string>, permission: IDynamicObj, index: number) => {
      if (permission.checked) accumulator.push(String(permission.value));
      permissionRequestedRecord = { ...permissionRequestedRecord, [index]: Boolean(permission.checked) };
      return accumulator;
    }, []);
    setPermissionsRequested({
      ...permissionsRequested,
      required: filteredPermissions.includes('REQUIRED'),
      promotional: filteredPermissions.includes('PROMOTIONAL')
    });
    setPermissionsRequestedValues(permissionRequestedRecord);
  };

  const insuranceCheckHandler = ({ value }: IDynamicObj) => {
    const permissions = value as Record<string, string | boolean>[];
    const filteredPermissions: Array<string> = permissions.reduce((accumulator: Array<string>, permission: IDynamicObj) => {
      if (permission.checked) accumulator.push(String(permission.value));
      return accumulator;
    }, []);
    setPermissionsRequested({
      ...permissionsRequested,
      insurance: filteredPermissions.length > 0
    });
  };

  const surveyHandleSubmit = () => {
    const requestBody = {
      offeringsRequested: offeringsRequested,
      permissionsRequested: Object.keys(permissionsRequested)
        .filter((k) => permissionsRequested[k] === true)
        .map((k) => k.toUpperCase()),
      applicationUUId: String(signAndSubmit.applicationUUId),
      origin: isCreditApp ? 'FC' : 'FP'
    };
    fireAPI({ params: requestBody });
  };

  useEffect(() => {
    updateRootPage('survey');
    const pageSpecificDigitalData = {
      page: {
        tool: 'event:lead submitted',
        toolDescriptor: 'lead submitted:credit:cloc'
      },
      user: {
        leadType: 'credit:cloc'
      }
    };
    fireAnalyticsLoad({ pageName: 'survey', appId: String(signAndSubmit?.applicationUUId), pageSpecificDigitalData });
    resetLS();
  }, []);

  useEffect(() => {
    validate();
  }, [permissionsRequested]);

  const navBtns = () => {
    return [
      <Btn
        key="returnHome"
        name="returnHome"
        directProps={{ icon: 'caretLeft', iconPosition: 'left', size: 'small' }}
        onClick={() => handleReturnHome(homePath)}
      />,
      <Btn
        key="submit"
        name="submit"
        onClick={surveyHandleSubmit}
        disabled={!enableSubmit || apiState.loading}
      />
    ];
  };

  const renderSurvey = () => {
    return (
      <div
        data-testid="sur-cloc-cust"
        className={styles.section}>
        <Text name="cloc-survey-title" />
        <div className={styles.sectionHeading}>
          <div className={`${styles.headingIcon} ${styles.success}`}>
            <Success size="xl" />
          </div>
          <Text
            name="cloc-survey-para"
            data-testid={'cloc-survey-para'}
          />
        </div>
        <div className={styles.clocContact}>
          <Text name="cloc-survey-contact" />
        </div>
        <Divider style={{ marginTop: '32px' }} />
        <Text
          name="cloc-survey-heading-info"
          className={styles.headingInfo}
        />
        <Checkbox
          className={styles.offeringsCheckox}
          pagePath={AEM_PATH}
          name="survey-offerings"
          changeHandler={offeringCheckHandler}
          validate={false}
        />
        <Divider style={{ marginTop: '32px' }} />
        <Checkbox
          pagePath={AEM_PATH}
          className={styles.permissionsCheckox}
          name="sur-non-insurance"
          changeHandler={nonInsuranceCheckHandler}
          validate={false}
          value={permissionsRequestedValues}
        />
        {(showInsurance || isAuthorView) && (
          <div className={styles.conditionalCheckbox}>
            <Checkbox
              pagePath={AEM_PATH}
              name="sur-insurance"
              changeHandler={insuranceCheckHandler}
              validate={false}
            />
          </div>
        )}

        <BtnGroup
          className={styles.nav}
          btns={navBtns()}
        />
      </div>
    );
  };

  if (region === 'us') {
    return (
      <>
        {((!(apiState.data || apiState.error) && !submitError) || isAuthorView) && renderSurvey()}
        {(apiState.data || isAuthorView) && <ThankYouPage />}
        {(apiState.error || submitError || isAuthorView) && <ErrorPage submitError={submitError} />}
      </>
    );
  } else {
    return (
      <>
        {!submitError && <CanadaThankYou />}
        {(isAuthorView || submitError) && <ErrorPage submitError={submitError} />}
      </>
    );
  }
};

export default Survey;
