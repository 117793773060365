import { useState } from 'react';

import useClocStore from '../../store';
import { IClocState, IDynamicObj } from '../../store/types';
import { formatByteSize } from '../../utils/jsUtils';
import useFetchHook from '../../utils/useFetchHook';
import log from '../logToApi';
import { SubmitApiDataCA, SubmitApiDataUS } from './apiTypes';
import { transformDataCA, transformDataUS } from './transformations';
import { attachNestedDocuments, getRequestPath } from './utils';

declare const window: any;

interface ApiState {
  loading: boolean;
  data: unknown;
  error: unknown;
}

export interface UseSubmitApi {
  fireAPI: () => Promise<void>;
  apiState: ApiState;
}

const debounce = <T extends (...args: any[]) => any>(func: T, delay: number) => {
  let debounceTimer: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>): ReturnType<T> {
    clearTimeout(debounceTimer);
    return new Promise<ReturnType<T>>((resolve) => {
      debounceTimer = setTimeout(() => resolve(func.apply(this, args)), delay);
    }) as ReturnType<T>;
  };
};

const useSubmitApi = (): UseSubmitApi => {
  const region = useClocStore((state) => (state as IClocState).region);
  const locale = useClocStore((state) => (state as IClocState).locale);
  const updateFormData = useClocStore((state) => state.updateFormData);
  const appType = useClocStore((state) => (state as IClocState).appType);
  const baseUrl = process.env.CLOC_SERVICE_URL;
  const updateSubmitError = useClocStore((state) => state.updateSubmitError);
  const updateRootPage = useClocStore((state) => state.updateRootPage);
  const updateIsLoading = useClocStore((state) => state.updateIsLoading);

  const isCreditApp = appType === 'credit';

  const fireAPIInternal = useFetchHook();

  const [apiState, setApiState] = useState<ApiState>({
    loading: false,
    data: null,
    error: null
  });

  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus);
  const financialStatementsDocs = useClocStore((state) => (state as IClocState).financialStatementsDocs);
  const businessInformation = useClocStore((state) => (state as IClocState).clocFormData.businessInformation);

  const requestPath = getRequestPath(region);
  const path = `${baseUrl}${requestPath}`;

  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    updateFormData('signAndSubmit', (obj: IDynamicObj) => {
      obj[name as string] = value;
    });
  };

  const responseHandler = async (response: Response) => {
    try {
      const data = await response.json();
      if (response.ok) {
        setApiState({
          loading: false,
          data: data,
          error: null
        });
        updateIsLoading(false);
        changeHandler({ name: 'applicationUUId', value: data.applicationUUId });
        changeHandler({ name: 'isSubmitSuccess', value: true });
        updateSubmitError(false);
      } else {
        throw new Error('Submit API request failed');
      }
    } catch (error) {
      setApiState({
        loading: false,
        data: null,
        error
      });
      updateIsLoading(false);
      changeHandler({ name: 'isSubmitSuccess', value: false });
      updateSubmitError(true);
      updateRootPage('survey');
    }
  };

  const errorHandler = (obj: Record<string, unknown>) => {
    setApiState({
      loading: false,
      data: null,
      error: obj.error
    });
    updateIsLoading(false);
    changeHandler({ name: 'isSubmitSuccess', value: false });
    updateSubmitError(true);
    updateRootPage('survey');

    (async () => {
      await fireAPIInternal({
        path: `${baseUrl}/v1/log`,
        options: {
          method: 'POST',
          body: JSON.stringify({
            message: 'Customer submission error: ' + obj.error
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      });
    })();
  };

  const fireAPI = async () => {
    console.log('🔥 Inside fireAPI function');
    const formToSubmit = new FormData();
    const localeForCA = locale === 'fr' ? 'fr-ca' : 'en-ca';
    const transformedData: SubmitApiDataUS | SubmitApiDataCA =
      region === 'us' ? transformDataUS(validationStatus, isCreditApp) : transformDataCA(validationStatus, localeForCA, isCreditApp);

    const formInfo = new Blob([JSON.stringify(transformedData)], {
      type: 'application/json'
    });

    formToSubmit.append('applicationData', formInfo);

    const origin = isCreditApp ? 'FC' : 'FP';
    formToSubmit.append('origin', origin);

    const getFileDetailsAndTotalSize = (docs: File[]) => {
      const totalSize = docs.reduce((acc, file) => acc + file.size, 0);
      const fileDetails = docs.map((file) => `${file.name} (${formatByteSize(file.size.toString())})`).join(', ');
      return { totalSize: formatByteSize(totalSize.toString()), fileDetails };
    };
    if (region === 'us') {
      attachNestedDocuments(formToSubmit, financialStatementsDocs.mostRecentDocs as File[]);
      attachNestedDocuments(formToSubmit, financialStatementsDocs.secondRecentDocs as File[]);
      attachNestedDocuments(formToSubmit, financialStatementsDocs.thirdRecentDocs as File[]);
      const { totalSize, fileDetails } = getFileDetailsAndTotalSize([
        ...(financialStatementsDocs.mostRecentDocs || []),
        ...(financialStatementsDocs.secondRecentDocs || []),
        ...(financialStatementsDocs.thirdRecentDocs || [])
      ]);

      log.info(
        `Total FileSize: ${totalSize}, Files Uploaded: ${fileDetails}, Business Name: ${String(businessInformation.businessName) || '-'}`
      );
    } else {
      attachNestedDocuments(formToSubmit, financialStatementsDocs.canadaDocs as File[]);
    }
    setApiState({
      loading: true,
      data: null,
      error: null
    });
    updateIsLoading(true);

    if (window.grecaptcha?.enterprise) {
      window.grecaptcha.enterprise.ready(async () => {
        const recaptchaToken = await window.grecaptcha.enterprise?.execute(process.env.CAPTCHA_SITE_KEY, {
          action: 'SUBMIT'
        });

        formToSubmit.append('recaptchaToken', recaptchaToken);

        await fireAPIInternal({
          path,
          options: {
            method: 'POST',
            body: formToSubmit
          },
          resHandler: responseHandler,
          errorHandler
        });
      });
    } else {
      console.log('Google reCAPTCHA enterprise not loaded');
      await fireAPIInternal({
        path,
        options: {
          method: 'POST',
          body: formToSubmit
        },
        resHandler: responseHandler,
        errorHandler
      });
    }
  };

  const debouncedFireAPI = debounce(fireAPI, 1000);

  return { fireAPI: debouncedFireAPI, apiState };
};

export default useSubmitApi;
