import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Divider } from '@fcamna/react-library';
import { useRef } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import DatePicker from '../../atoms/datePicker';
import InlineAlert from '../../atoms/inlineAlert';
import Input from '../../atoms/input';
import Text from '../../atoms/text';
import WithAddMore from '../../molecules/withAddMore';
import styles from './fleetInfo.module.scss';

const LENDER_LIMIT = 4;

interface LenderFormProps {
  validate?: boolean;
  index: number;
}

interface WarningFooterProps {
  length: number;
  limit: number;
}

const WarningFooter = ({ length, limit }: WarningFooterProps) => {
  return (
    <div className={length === 1 ? styles.maxAlert : styles.alert}>
      {length === limit ? (
        <div className={styles.maxAlert}>
          <InlineAlert
            name="warningAlert"
            titleOnly
          />
        </div>
      ) : null}
    </div>
  );
};

const startYear = new Date().getFullYear();
const endYear = startYear + 100;

const LenderForm = ({ validate = false, index }: LenderFormProps) => {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);
  const region = useClocStore((state) => (state as IClocState).region);

  const lenderInfo: Record<string, string | Record<string, string>> = useClocStore((state) => {
    const fleetInfo = (state as IClocState).clocFormData.fleetInformation;
    if (Array.isArray(fleetInfo.loans) && typeof index === 'number') {
      return fleetInfo.loans[index] as Record<string, string>;
    }
    return {} as Record<string, string>;
  });

  const changeHandler = ({ name, value }: IDynamicObj) => {
    updateFormData('fleetInformation', (obj: IDynamicObj) => {
      (obj.loans as IDynamicObj[])[index][name as string] = value as string;
    });
  };

  return (
    <div className={styles.form}>
      <Text
        name={`lender-heading`}
        replacements={{ 'num': `${index + 1}` }}
      />
      <Input
        label="Name of Lender"
        name="lender"
        type="text"
        defaultValue={lenderInfo?.lender as string}
        validate={validate}
        changeHandler={changeHandler}
        required
        helperText="Name of Lender is required"
        dataTestId="lenderInput"
        showBlankSpaceValidation
      />
      <Input
        label="Limit"
        name="limit"
        type="text"
        defaultValue={lenderInfo?.limit as string}
        validate={validate}
        changeHandler={changeHandler}
        required
        inputTypeFormat="currency"
        helperText="Limit is required"
        dataTestId="limit"
        showBlankSpaceValidation
        region={region}
      />
      <Input
        label="Balance"
        name="balance"
        type="text"
        defaultValue={lenderInfo?.balance as string}
        validate={validate}
        changeHandler={changeHandler}
        required
        inputTypeFormat="currency"
        dataTestId="balance"
        helperText="Balance is required"
        showBlankSpaceValidation
        region={region}
      />
      <DatePicker
        name="maturity"
        validate={validate}
        value={lenderInfo?.maturity as Record<string, string>}
        changeHandler={changeHandler}
        required
        selectorStartingYear={startYear}
        selectorEndingYear={endYear}
      />
    </div>
  );
};

const Lender = ({ validate }: { validate: boolean }) => {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);

  const isAuthorView = AuthoringUtils.isInEditor();
  const doesBusinessHaveLineOfCredit = useClocStore(
    (state) => (state as IClocState).clocFormData.fleetInformation.doesBusinessHaveLineOfCredit
  );
  const loans = (useClocStore.getState() as IClocState).clocFormData.fleetInformation.loans as IDynamicObj[];
  const initalLength = useRef<number>(loans?.length);

  const addCallback = () => {
    updateFormData('fleetInformation', (obj: IDynamicObj) => {
      (obj.loans as IDynamicObj[]).push({ lender: '' });
    });
  };

  const removeCallback = (idx: number) => {
    updateFormData('fleetInformation', (obj: IDynamicObj) => {
      (obj.loans as IDynamicObj[]).splice(idx, 1);
    });
    initalLength.current -= 1;
  };

  return (
    <>
      {doesBusinessHaveLineOfCredit === 'Yes' || isAuthorView ? (
        <div
          className={styles.forms}
          data-testid="LenderForm">
          <Text name="lender-info" />
          <WithAddMore
            id="lenders"
            initLength={isAuthorView ? 1 : loans?.length}
            renderComp={(index: number, totalElems: number = 0) => (
              <>
                <LenderForm
                  validate={validate && index < initalLength.current}
                  index={index}
                />
                {index < totalElems - 1 ? <Divider style={{ marginTop: '32px' }} /> : null}
              </>
            )}
            addCallback={addCallback}
            removeCallback={removeCallback}
            maxLimit={LENDER_LIMIT}
            renderFooter={(length: number) => (
              <WarningFooter
                length={length}
                limit={LENDER_LIMIT}
              />
            )}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Lender;
