// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'FordMotion';
  font-weight: 400;
  font-display: swap;
  src:
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Regular.woff2') format('woff2'),
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Regular.woff') format('woff');
}
@font-face {
  font-family: 'FordMotion';
  font-weight: 500;
  font-display: swap;
  src:
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Medium.woff2') format('woff2'),
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Medium.woff') format('woff');
}
@font-face {
  font-family: 'FordMotion';
  font-weight: 600;
  font-display: swap;
  src:
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-SemiBold.woff2') format('woff2'),
    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-SemiBold.woff') format('woff');
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;;2FAEyF;AAC3F;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;;0FAEwF;AAC1F;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;;4FAE0F;AAC5F","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');\n\n@font-face {\n  font-family: 'FordMotion';\n  font-weight: 400;\n  font-display: swap;\n  src:\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Regular.woff2') format('woff2'),\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Regular.woff') format('woff');\n}\n@font-face {\n  font-family: 'FordMotion';\n  font-weight: 500;\n  font-display: swap;\n  src:\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Medium.woff2') format('woff2'),\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-Medium.woff') format('woff');\n}\n@font-face {\n  font-family: 'FordMotion';\n  font-weight: 600;\n  font-display: swap;\n  src:\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-SemiBold.woff2') format('woff2'),\n    url('https://cxc-assets.fordpro.com/fonts/ford/FordMotion-SemiBold.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
