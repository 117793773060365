import Markdown from 'markdown-to-jsx';
import { ReactElement } from 'react';

import Btn, { default as ButtonWrapper } from '../../atoms/button';
import fordProIcons from '../../atoms/icon';
import styles from './reviewDetailsCard.module.scss';

const { CheckmarkFilled: Success, Warning } = fordProIcons;

export interface IReviewDetailsCardProps {
  section: IFormSectionForReview;
  gridView?: boolean;
  modifyHandler?: any;
  jsxToRender?: ReactElement;
}

export interface IFormSectionForReview {
  title: string;
  isValid: boolean;
  subSections: Array<{
    id?: string; // Assume a unique id is available for each subsection
    title?: string | ReactElement;
    fields: Array<{
      id?: string; // Assume a unique id is available for each field
      title: string | ReactElement;
      value?: string | ReactElement | null;
      isOptional: boolean;
    }>;
  }>;
}

const ReviewDetailsCard = ({ section, gridView = true, modifyHandler, jsxToRender }: IReviewDetailsCardProps) => {
  function renderSubsecTitle(subsec: any) {
    if (subsec.title) {
      return typeof subsec.title === 'string' ? (
        <Markdown className={styles.subsecHeading}>{subsec.title}</Markdown>
      ) : (
        <div className={`${styles.subsecHeading} ${styles.customSubsecHeading}`}>{subsec.title}</div>
      );
    }
    return null;
  }

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.sectionHeading}>
        <div className={`${styles.headingIcon} ${section.isValid ? styles.success : styles.warning}`}>
          {section.isValid ? <Success size="l" /> : <Warning size="l" />}
        </div>
        <Markdown className={styles.cardHeading}>{section.title}</Markdown>
      </div>
      {section.subSections.map((subsec) => (
        <div
          className={styles.subsec}
          key={subsec.id}>
          {renderSubsecTitle(subsec)}
          <div className={`${styles.row} ${gridView ? styles.gridView : ''}`}>
            {subsec.fields.map((field) =>
              !field.isOptional || field.value ? (
                <div key={field.id}>
                  <div>{typeof field.title === 'string' ? <Markdown className={styles.fldName}>{field.title}</Markdown> : field.title}</div>
                  {field.value ? (
                    <div className={styles.fieldValue}>
                      {typeof field.value === 'string' ? <Markdown className={styles.fldVal}>{field.value.trim()}</Markdown> : field.value}
                    </div>
                  ) : (
                    <div className={styles.missingValueInlineAlert}>
                      <ButtonWrapper
                        name="review-missing-alert"
                        onClick={modifyHandler}
                      />
                    </div>
                  )}
                </div>
              ) : null
            )}
          </div>
        </div>
      ))}
      {jsxToRender}
      <div className={styles.buttonWrapper}>
        {!section.isValid ? (
          <Btn
            name={`review-modify`}
            onClick={modifyHandler}
            directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
          />
        ) : (
          <Btn
            name={`review-modify-outline`}
            onClick={modifyHandler}
            directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewDetailsCard;
