import useClocStore from '../../../store';
import { analyticsDigitalData, CookiePreferences, IAnalyticsDetails } from './types';

// page specs credit
const SPECS_CREDIT: Record<string, string> = {
  screener: 'flmc:commercial:cloc:customize loan application',
  businessInfo: 'flmc:commercial:cloc:business information',
  contactInfo: 'flmc:commercial:cloc:business contact information',
  fleetInfo: 'flmc:commercial:cloc:fleet information',
  billingInfo: 'flmc:commercial:cloc:billing information',
  cccd: 'flmc:commercial:cloc:cross collateral agreement',
  statements: 'flmc:commercial:cloc:financial history',
  submit: 'flmc:commercial:cloc:terms and conditions',
  survey: 'flmc:commercial:cloc:complete'
};

// page specs pro
const SPECS_PRO: Record<string, string> = {
  screener: 'fpro:financing:cloc:0 prestart',
  businessInfo: 'fpro:financing:cloc:1 start',
  contactInfo: 'fpro:financing:cloc:2 contact',
  fleetInfo: 'fpro:financing:cloc:3 fleet',
  billingInfo: 'fpro:financing:cloc:4 billing',
  cccd: 'fpro:financing:cloc:5 collateral',
  statements: 'fpro:financing:cloc:6 history',
  review: 'fpro:financing:cloc:6b review',
  submit: 'fpro:financing:cloc:7 terms',
  survey: 'fpro:financing:cloc:8 complete'
};

export function track(tag: string, variableDigitalData: analyticsDigitalData) {
  if (variableDigitalData.user?.financeNumberOfVehicles)
    variableDigitalData.user.financeNumberOfVehicles = stringifyFinancedNumberOfVehicles(
      variableDigitalData.user.financeNumberOfVehicles
    ) as any;
  window.digitaldata = generateAllDigitalData(window.location.hostname, window.innerWidth, variableDigitalData);
  if (window._satellite) {
    window._satellite.track(tag);
  }
}

declare global {
  interface Window {
    digitaldata: analyticsDigitalData;
    _satellite: {
      track: (tag: string, data?: any) => void;
    };
  }
}

const stringifyFinancedNumberOfVehicles = (numberOfVehicles: any) => {
  const total = numberOfVehicles.lease + numberOfVehicles.trac + numberOfVehicles.retail;
  return JSON.stringify({ total, ...numberOfVehicles })
    .replace(/["{}]/g, '')
    .replace(/,/g, '|');
};

function generateAllDigitalData(hostname: string, pageWidth: number, variableDigitalData: analyticsDigitalData) {
  const commonDigitalData = {
    page: {
      client: variableDigitalData.page.client ?? 'fmcc-us',
      site: variableDigitalData.page.site ?? hostname,
      userLanguage: variableDigitalData.page.userLanguage ?? 'eng',
      siteSection: variableDigitalData.page.siteSection ?? 'commercial',
      radUIVersion: getRadUiVersion(pageWidth)
    }
  };
  const digitalData = JSON.parse(JSON.stringify(variableDigitalData));
  digitalData.page = { ...digitalData.page, ...commonDigitalData.page };
  return digitalData;
}

function getRadUiVersion(pageWidth: number) {
  if (pageWidth < 768) {
    return 'ui:rad:mobile';
  }
  if (pageWidth < 992) {
    return 'ui:rad:tablet';
  }
  return 'ui:rad:pc';
}

const doesUserOptInNonEssentialCookies = (cookies: CookiePreferences | undefined) => {
  if (cookies?.functionality && cookies?.performance) {
    return true;
  }

  return false;
};

export const getExperienceType = () => {
  if (window.innerWidth < 768) {
    return 'mobile';
  }
  if (window.innerWidth < 992) {
    return 'tablet';
  }
  return 'desktop';
};

const getUserDigitalData = (applicationUUid?: string) => {
  if (applicationUUid && applicationUUid != null && applicationUUid != '') {
    return {
      cksVisitId: 'financeclocwebform-' + applicationUUid
    };
  }
  return {};
};

const financeDigitalData = (applicationUUid?: string) => {
  const region = useClocStore.getState().region;
  const appType = useClocStore.getState().appType;

  if (appType === 'pro') {
    return {};
  }

  if (!applicationUUid) {
    return {
      finance: {
        accountOption: 'self managed:commercial'
      }
    };
  }

  if (region === 'us') {
    return {
      finance: {
        accountOption: 'self managed:commercial',
        leadOfferingID: 'FinanceCLOCWebformFordCredit-' + applicationUUid
      }
    };
  }

  return {
    finance: {
      accountOption: 'self managed:commercial'
    }
  };
};

const getClient = () => {
  const region = useClocStore.getState().region;
  const appType = useClocStore.getState().appType;

  const appKey = appType === 'pro' ? 'fpro' : 'fmcc';
  const rg = region === 'us' ? 'us' : 'can';

  return `${appKey}-${rg}`;
};

const getLang = () => {
  const locale = useClocStore.getState().locale;

  return locale === 'fr' ? 'fre' : 'eng';
};

const getOnCLickDigitalData = (onCLickObj: any) => {
  if (onCLickObj?.onclickLinkName) {
    return {
      onclick: onCLickObj
    };
  }
  return null;
};

export const analyticsOnLoadHelper = ({ pageName, appId, pageSpecificDigitalData }: IAnalyticsDetails): void => {
  const client = getClient();
  const userLanguage = getLang();
  const pg = client.includes('fpro') ? SPECS_PRO[pageName] : SPECS_CREDIT[pageName];
  const hierarchy = client.includes('fpro') ? 'fpro:financing:cloc' : 'cloc';
  const siteSection = client.includes('fpro') ? 'financing' : 'cloc';
  const site = window.location.hostname;
  const additionalPageData = pageSpecificDigitalData?.page || {};
  const additionalUserData = pageSpecificDigitalData?.user || {};
  const additionalOnCLickData = getOnCLickDigitalData(pageSpecificDigitalData?.onclick) || {};

  const variableDigitalData: any = {
    page: {
      client,
      hierarchy,
      pageName: pg,
      pageNameNoVehicle: pg,
      site,
      siteSection,
      userLanguage,
      variantName: 'cloc-form',
      pageType: 'credit application',
      experienceType: getExperienceType(),
      pageDomain: window.location.href,
      ...additionalPageData,
      ...(appId && { applicationId: appId })
    },
    user: {
      ...getUserDigitalData(appId),
      ...additionalUserData
    },
    ...additionalOnCLickData
  };

  if (track) {
    track('global-load-cloc', variableDigitalData);
  }
};
