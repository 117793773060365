import { fpds, QUERIES, zIndices } from '@fcamna/shared-component-foundations';
import styled from 'styled-components';

import { ProgressTrackerProps, ProgressTrackerTheme } from './types';

const spaces = ['10px', '50px', '18px', '8px'];

// To get size of marker
const getSize = (type: string) => {
  if (type === 'large') {
    return fpds.primitive.space['8x'].value;
  } else if (type === 'small') {
    return fpds.primitive.space['6x'].value;
  }
  return fpds.primitive.space['7x'].value;
};

const colors = {
  activeColor: fpds.primitive.color.skyview['1000'].value,
  errorColor: fpds.primitive.color.red['1000'].value,
  defaultColor: fpds.primitive.color.twilight['600'].value,
  defaultLabelColor: fpds.primitive.color.twilight['700'].value,
  activeBgColor: fpds.primitive.color.twilight['000'].value,
  labelColor: fpds.primitive.color.twilight['1000'].value,
  hoverColor: fpds.primitive.color.skyview['1200'].value
};

const ProgressTrackerContainer = styled.div<Partial<ProgressTrackerProps> & { $orientation?: string }>`
  display: flex;
  flex-direction: ${(props) => (props.$orientation === 'horizontal' ? 'column' : 'column-reverse')};
  @media ${QUERIES.laptopAndUp} {
    flex-direction: column;
  }

  .progress-tracker {
    display: flex;
    margin: ${fpds.primitive.space['1x'].value} auto;
    padding: 0;
    list-style: none;
    width: 100%;

    @media ${QUERIES.laptopAndUp} {
      margin: ${spaces[0]} auto;
    }

    &.vertical {
      width: max-content;
      margin: ${fpds.primitive.space['1x'].value} auto ${fpds.primitive.space['1x'].value} 0;
      height: 100%;
      flex-direction: column;

      @media ${QUERIES.laptopAndUp} {
        margin: ${spaces[0]} auto ${fpds.primitive.space['15x'].value} 0;
      }
      .progress-step {
        display: flex;
        flex: 1 1 auto;
        min-height: ${spaces[1]};
      }
      .progress-marker .connector {
        right: auto;
        top: calc(${getSize('small')} + ${fpds.primitive.space['1x'].value});
        left: calc(${getSize('small')} / 2);
        width: 2px;
        margin: ${fpds.primitive.space['1x'].value} 0;
        height: calc(100% - ${getSize('small')} - ${spaces[0]});
        border-radius: ${fpds.primitive.radius['4x'].value};
        @media ${QUERIES.laptopAndUp} {
          top: calc(${getSize('large')} + ${fpds.primitive.space['1x'].value});
          left: calc(${getSize('large')} / 2);
          height: calc(100% - ${getSize('large')} - ${spaces[0]});
        }
      }
      .progress-text {
        padding: 0 ${getSize('small')} calc(${getSize('small')} / 2) ${getSize('small')};
        @media ${QUERIES.laptopAndUp} {
          padding: 0 ${getSize('large')} calc(${getSize('large')} / 2) ${getSize('large')};
        }
      }
    }

    &.text-top {
      .progress-text {
        height: 100%;
      }
      .progress-marker {
        top: -${getSize('small')};
        @media ${QUERIES.laptopAndUp} {
          top: -${getSize('large')};
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      filter: grayscale(1);
      opacity: 0.7;
    }

    .progress-text {
      display: none;
      padding: ${fpds.primitive.space['2x'].value} ${fpds.primitive.space['2x'].value};
      overflow: hidden;
      text-overflow: ellipsis;

      .tracker-text {
        font-size: ${fpds.primitive.fontSize.xsmall.value};
        font-family: ${fpds.primitive.fontFamilies.inter.value};
        font-style: normal;
        font-weight: ${fpds.primitive.fontWeights.medium.value};
        line-height: ${fpds.primitive.lineHeights.small.value};
        color: ${colors.defaultLabelColor};
      }
      @media ${QUERIES.laptopAndUp} {
        display: block;
      }
    }

    .progress-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: ${fpds.primitive.fontSize.small.value};
      font-family: ${fpds.primitive.fontFamilies.inter.value};
      font-style: normal;
      font-weight: ${fpds.primitive.fontWeights.medium.value};
      line-height: ${fpds.primitive.lineHeights.medium.value};
      color: ${colors.defaultLabelColor};
    }

    .progress-link {
      display: block;
      position: relative;
    }

    .progress-step {
      flex: 1 1 0%;
      margin: 0;
      padding: 0;
      min-width: ${getSize('small')};
      @media ${QUERIES.laptopAndUp} {
        min-width: ${getSize('large')};
      }

      &.pointer {
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(1);
        opacity: 0.7;
      }

      &:last-child {
        /* flex-grow: 0; */
        .progress-marker {
          .connector {
            display: none;
          }
        }
      }

      .progress-marker {
        .indicator {
          border: 2px solid ${colors.defaultColor};
          color: ${colors.defaultColor};
          background-color: transparent;
        }
        &.clickable {
          & button.indicator {
            cursor: pointer;
            padding: 2px;
          }
          &:hover .indicator {
            border-color: ${colors.hoverColor};
            color: ${colors.hoverColor};
          }
        }
        .connector {
          background-color: ${colors.defaultColor};
        }
      }

      .progress-text {
        text-align: center;
      }

      &.is-active {
        .progress-marker {
          .indicator {
            border: none;
            color: ${colors.activeBgColor};
            background-color: ${colors.activeColor};
          }
          &.clickable:hover .indicator {
            background-color: ${colors.hoverColor};
            color: ${colors.activeBgColor};
          }
          .connector {
            background-color: ${colors.defaultColor};
          }
        }
        .progress-text {
          .progress-title {
            color: ${colors.labelColor};
          }
        }
      }

      &.is-error {
        .progress-marker {
          .indicator {
            border: 2px solid ${colors.errorColor};
            color: ${colors.errorColor};
            background-color: transparent;
          }
          .connector {
            background-color: ${colors.defaultColor};
          }
        }

        &.completed {
          .progress-marker .connector {
            background-color: ${colors.activeColor};
          }
        }

        .progress-text {
          .progress-title {
            color: ${colors.errorColor};
          }
        }

        &.is-active {
          .progress-marker {
            .indicator {
              border: none;
              color: ${colors.activeBgColor};
              background-color: ${colors.errorColor};
            }
          }
        }
      }

      &.is-complete {
        .progress-marker {
          .indicator {
            border: 2px solid ${colors.activeColor};
            color: ${colors.activeColor};
            background-color: transparent;
          }
          .connector {
            background-color: ${colors.activeColor};
          }
        }
        .progress-text {
          .progress-title {
            color: ${colors.labelColor};
          }
        }
        &.is-active {
          .progress-marker {
            .indicator {
              border: none;
              color: ${colors.activeBgColor};
              background-color: ${colors.activeColor};
            }
          }
        }
      }
    }

    .progress-marker {
      display: block;
      position: relative;

      .indicator {
        font-size: ${fpds.primitive.fontSize.xxsmall.value};
        font-weight: ${fpds.primitive.fontWeights.medium.value};
        font-family: ${fpds.primitive.fontFamilies.inter.value};
        line-height: ${fpds.primitive.lineHeights.xsmall.value};
        @media ${QUERIES.laptopAndUp} {
          font-size: ${fpds.primitive.fontSize.small.value};
          line-height: ${fpds.primitive.lineHeights.medium.value};
        }
        font-style: normal;
        &.text {
          padding: ${spaces[3]};
        }
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: ${zIndices.contentOverVideo};
        width: ${getSize('small')};
        height: ${getSize('small')};
        margin-left: none;
        margin-right: auto;
        border-radius: 50%;
        transition: background-color, border-color;
        transition-duration: 0.3s;
        @media ${QUERIES.laptopAndUp} {
          margin-left: auto;
          width: ${getSize('large')};
          height: ${getSize('large')};
        }

        svg {
          margin: auto;
          padding: 2px;
          width: ${fpds.primitive.space['3x'].value};
          height: ${fpds.primitive.space['3x'].value};
          @media ${QUERIES.laptopAndUp} {
            width: ${fpds.primitive.space['4x'].value};
            height: ${fpds.primitive.space['4x'].value};
          }
        }
      }
      .connector {
        content: '';
        display: block;
        position: absolute;
        border-radius: ${fpds.primitive.radius['1x'].value};
        z-index: ${zIndices.docked};
        top: calc(${getSize('small')} / 2);
        right: ${fpds.primitive.space['1x'].value};
        width: calc(100% - ${getSize('small')} - ${spaces[0]});
        height: 2px;
        transition:
          background-color 0.3s,
          background-position 0.3s;
        @media ${QUERIES.laptopAndUp} {
          right: calc(-50% + (${getSize('large')} + ${spaces[0]}) / 2);
          top: calc(${getSize('large')} / 2);
          width: calc(100% - ${getSize('large')} - ${spaces[0]});
        }
      }
    }
  }

  .stepContent {
    display: block;
    .progress-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      .tracker-text {
        font-size: ${fpds.primitive.fontSize.xsmall.value};
        font-family: ${fpds.primitive.fontFamilies.inter.value};
        font-style: normal;
        font-weight: ${fpds.primitive.fontWeights.medium.value};
        line-height: ${fpds.primitive.lineHeights.small.value};
        color: ${colors.defaultLabelColor};
      }
      .progress-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: ${fpds.primitive.fontSize.small.value};
        font-family: ${fpds.primitive.fontFamilies.inter.value};
        font-style: normal;
        font-weight: ${fpds.primitive.fontWeights.medium.value};
        line-height: ${fpds.primitive.lineHeights.medium.value};
        color: ${colors.labelColor};
      }
    }
    &.is-error {
      .progress-title {
        color: ${colors.errorColor};
      }
    }
    &.is-disable {
      cursor: not-allowed;
      filter: grayscale(1);
      opacity: 0.7;
    }
    &.pointer {
      cursor: pointer;
    }
    @media ${QUERIES.laptopAndUp} {
      display: none;
    }
  }
`;

export const FordProLightProgressTracker: ProgressTrackerTheme = {
  ProgressTrackerContainer
};
