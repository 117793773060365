import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import FieldError from '../../atoms/fieldError';
import IncrementInput from '../../atoms/incrementInput';
import Input from '../../atoms/input';
import RadioButtonGroup from '../../atoms/radio/radio';
import Text from '../../atoms/text';
import TextBox from '../../atoms/textArea';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import SearchDealerships from '../../molecules/searchDealerships';
import styles from './fleetInfo.module.scss';
import Lender from './Lender';
import RelatedBusinessInfo from './relatedBusiness';
import { validateSchema } from './validation';

const AEM_PATH = getAEMPath('fleetInformation');
const requiredAmount = 49999;

const EquipmentDescription = ({
  changeHandler,
  formInComplete
}: {
  changeHandler: ({ name, value }: IDynamicObj) => void;
  formInComplete: boolean;
}) => {
  const { addEquipment, addEquipmentDescription } = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation);
  const isAuthorView = AuthoringUtils.isInEditor();
  const [isSwitched, setIsSwitched] = useState(false);

  useEffect(() => {
    if (addEquipment === 'No') {
      setIsSwitched(true);
    }
  }, [addEquipment]);

  return (
    <>
      {addEquipment === 'Yes' || isAuthorView ? (
        <TextBox
          name="addEquipmentDescription"
          validate={formInComplete && !isSwitched}
          defaultValue={addEquipmentDescription as string}
          changeHandler={changeHandler}
          dataTestId="equipmentDescTextBox"
          required
          customErrorKeys={['required', 'blankspace']}
          showBlankSpaceValidation
          aemPath={AEM_PATH}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const FleetInformation = () => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const RADIO_OPTIONS = ['Yes', 'No'];
  useValidationStatus({ key: 'fleetInformation', validationFn: validateSchema });

  const updateFormData = useClocStore((state) => state.updateFormData);
  const region = useClocStore((state) => (state as IClocState).region);
  const isValid = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation.isValid);
  const defaultValues: MutableRefObject<IDynamicObj> = useRef((useClocStore.getState() as IClocState).clocFormData.fleetInformation);
  const {
    isVisited,
    numberOfEmployees,
    primaryRevenueSource,
    intendedUseOfVehicle,
    projectedFleetSize,
    replacementUnits,
    additionalUnits,
    annualMilesPerUnit,
    requestedLineAmount,
    annualKilometersPerUnit
  } = defaultValues.current;
  const fleetInformation = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation);
  const hasRelatedBusiness = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation.hasRelatedBusiness);
  const doesBusinessHaveLineOfCredit = useClocStore(
    (state) => (state as IClocState).clocFormData.fleetInformation.doesBusinessHaveLineOfCredit
  );
  const addEquipment = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation.addEquipment);
  const formInComplete = !!(isVisited && !isValid);

  const showUnitsError = () => {
    if (region === 'us' && (fleetInformation.requestedLineAmount || formInComplete)) {
      const replacementUnitsValue = fleetInformation?.replacementUnits || 0;
      const additionalUnitsValue = fleetInformation?.additionalUnits || 0;
      return replacementUnitsValue === 0 && additionalUnitsValue === 0;
    }
  };

  const minimumValueReached = () => {
    // Check if region is 'us' and requestedLineAmount is defined
    if (
      region === 'us' &&
      ((fleetInformation.requestedLineAmount !== undefined && fleetInformation.requestedLineAmount !== null) || formInComplete)
    ) {
      let requestedLine = fleetInformation.requestedLineAmount;

      // Check if requestedLine is a string and has a length greater than 0
      if (typeof requestedLine === 'string' && requestedLine.length <= 1) {
        return false;
      }

      // Convert to number if it's a string
      if (typeof requestedLine === 'string') {
        requestedLine = Number(requestedLine.replace(/[^0-9.-]+/g, ''));
      }

      // Ensure requestedLine is a number before comparing
      if (typeof requestedLine === 'number' && !isNaN(requestedLine)) {
        return requestedLine <= requiredAmount;
      }
    }

    return false;
  };

  const changeHandler = ({ name, value }: IDynamicObj) => {
    // set isValid true when all field are valid and form completes else keep false.
    updateFormData('fleetInformation', (obj: IDynamicObj) => {
      if (name === 'doesBusinessHaveLineOfCreditCA') {
        name = 'doesBusinessHaveLineOfCredit';
      }
      obj[name as string] = value;
      if (name === 'doesBusinessHaveLineOfCredit') {
        const loans = value === 'Yes' ? [{ lender: '' }] : undefined;
        obj.loans = loans as Record<string, string | number>[];
      }
      if (name === 'hasRelatedBusiness' && value === 'No') {
        delete obj.relatedBusinessName;
        delete obj.relatedBusinessDescription;
        delete obj.willRelatedBusinessRequireCredit;
        delete obj.willRelatedBusinessSubleaseVehicles;
      }
      if (name === 'addEquipment' && value === 'No') {
        delete obj.addEquipmentDescription;
      }
    });
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('fleetInformation', (obj: IDynamicObj) => {
        obj.isVisited = true;
        obj.loans = [] as Record<string, string | Record<string, string>>[];
      });
    }

    fireAnalyticsLoad({ pageName: 'fleetInfo' });
  }, []);

  useEffect(() => {
    if (region === 'us') {
      minimumValueReached();
    }
  }, [replacementUnits, additionalUnits, requestedLineAmount]);

  return (
    <>
      <div className={styles.section}>
        <Text name="fleet-information" />
        <div className={styles.form}>
          <div>
            <RadioButtonGroup
              name="hasRelatedBusiness"
              defaultValue={hasRelatedBusiness as string}
              validate={formInComplete}
              optionsValue={RADIO_OPTIONS}
              changeHandler={changeHandler}
              dataTestId="relatedBusinessRadioGroup"
              required
              aemPath={AEM_PATH}
            />
          </div>

          <RelatedBusinessInfo
            changeHandler={changeHandler}
            formInComplete={formInComplete && hasRelatedBusiness === 'Yes'}
            aemPath={AEM_PATH}
          />

          {region === 'us' ? (
            <div>
              <RadioButtonGroup
                name="doesBusinessHaveLineOfCredit"
                validate={formInComplete}
                defaultValue={doesBusinessHaveLineOfCredit as string}
                optionsValue={RADIO_OPTIONS}
                changeHandler={changeHandler}
                dataTestId="lenderRadioGroup"
                required
                aemPath={AEM_PATH}
              />
            </div>
          ) : (
            <div>
              <RadioButtonGroup
                name="doesBusinessHaveLineOfCreditCA"
                validate={formInComplete}
                defaultValue={doesBusinessHaveLineOfCredit as string}
                optionsValue={RADIO_OPTIONS}
                changeHandler={changeHandler}
                dataTestId="lenderRadioGroup"
                required
                aemPath={AEM_PATH}
              />
            </div>
          )}
          <Lender validate={formInComplete} />
          <div>
            <RadioButtonGroup
              name="addEquipment"
              validate={formInComplete}
              defaultValue={addEquipment as string}
              optionsValue={RADIO_OPTIONS}
              changeHandler={changeHandler}
              dataTestId="addEquipmentRadioGroup"
              required
              aemPath={AEM_PATH}
            />
          </div>
          <EquipmentDescription
            changeHandler={changeHandler}
            formInComplete={formInComplete && addEquipment === 'Yes'}
          />
          <Input
            name="numberOfEmployees"
            defaultValue={numberOfEmployees as string}
            type="text"
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            inputTypeFormat="numeric"
            showBlankSpaceValidation
            dataTestId="employeeInput"
          />

          <Input
            name="primaryRevenueSource"
            defaultValue={primaryRevenueSource as string}
            type="text"
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            dataTestId="revenueInput"
            showBlankSpaceValidation
          />
          <TextBox
            name="intendedUseOfVehicle"
            defaultValue={intendedUseOfVehicle as string}
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            dataTestId="vehicleInput"
            customErrorKeys={['required', 'blankspace']}
            showBlankSpaceValidation
            aemPath={AEM_PATH}
          />
          <Text
            name="intendedUseOfVehicle-desc"
            className={styles.desc}
            bodySize="s"
            bodyWeight="m"
            bodyColor="subtle"
          />
        </div>
      </div>
      <div className={styles.section}>
        <Text name="projected-fleet" />
        <Text name="projected-fleet-subheading" />
        <div className={styles.form}>
          <Input
            name="projectedFleetSize"
            defaultValue={projectedFleetSize as string}
            type="text"
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            inputTypeFormat="numeric"
            showBlankSpaceValidation
            dataTestId="fleetInput"
          />
          <Text
            name="fleetSize-desc"
            className={styles.desc}
            bodySize="s"
            bodyWeight="m"
            bodyColor="subtle"
          />
          <IncrementInput
            name="replacementUnits"
            defaultValue={replacementUnits as number}
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            aemPath={AEM_PATH}
          />
          {(showUnitsError() || isAuthorView) && (
            <FieldError
              className={styles.desc}
              name={`total-replacement-additional-units`}
            />
          )}
          <Text
            name="replacement-desc"
            className={styles.desc}
            bodySize="s"
            bodyWeight="m"
            bodyColor="subtle"
          />
          <IncrementInput
            name="additionalUnits"
            defaultValue={additionalUnits as number}
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            aemPath={AEM_PATH}
          />
          {(showUnitsError() || isAuthorView) && (
            <FieldError
              className={styles.desc}
              name={`total-replacement-additional-units`}
            />
          )}
          <Text
            name="additional-desc"
            className={styles.desc}
            bodySize="s"
            bodyWeight="m"
            bodyColor="subtle"
          />
          {region === 'us' ? (
            <>
              <Input
                name="annualMilesPerUnit"
                defaultValue={annualMilesPerUnit as string}
                type="text"
                validate={formInComplete}
                changeHandler={changeHandler}
                required
                dataTestId="milesInput"
                inputTypeFormat="numeric"
                showBlankSpaceValidation
              />
              <Text
                name="miles-desc"
                className={styles.desc}
                bodySize="s"
                bodyWeight="m"
                bodyColor="subtle"
              />
            </>
          ) : (
            <>
              <Input
                name="annualKilometersPerUnit"
                defaultValue={annualKilometersPerUnit as string}
                type="text"
                validate={formInComplete}
                changeHandler={changeHandler}
                required
                dataTestId="milesInput"
                inputTypeFormat="numeric"
                showBlankSpaceValidation
              />
              <Text
                name="kilometer-desc"
                className={styles.desc}
                bodySize="s"
                bodyWeight="m"
                bodyColor="subtle"
              />
            </>
          )}
          <Input
            name="requestedLineAmount"
            defaultValue={requestedLineAmount as string}
            type="text"
            validate={formInComplete}
            changeHandler={changeHandler}
            required
            dataTestId="lineInput"
            inputTypeFormat="currency"
            showBlankSpaceValidation
            region={region}
          />
          {(region === 'us' && minimumValueReached()) || isAuthorView ? (
            <FieldError
              className={styles.desc}
              name={`requestedLineAmount-limit`}
            />
          ) : null}
          <Text
            name="lineAmount-desc"
            className={styles.desc}
            bodySize="s"
            bodyWeight="m"
            bodyColor="subtle"
          />
        </div>
      </div>
      <div className={styles.section}>
        <Text name="dealers-heading" />
        <SearchDealerships
          changeHandler={changeHandler}
          formInComplete={formInComplete}
          defaultValues={defaultValues.current}
          region={region}
          aemPath={AEM_PATH}
        />
      </div>
    </>
  );
};

export default FleetInformation;
