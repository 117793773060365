import { EditableAccordion, IAccordionItemProps } from '@fcamna/aem-library';
import { Accordion as AccordionCmp, AccordionItem } from '@fcamna/react-library';

interface AccordionProps {
  name: string;
  accordionClassName?: string;
  accordionContent?: React.ReactElement | string;
}

export default function Accordion({ name, accordionClassName = '', accordionContent = '' }: Readonly<AccordionProps>) {
  const isTestEnvironment = process.env.NODE_ENV === 'test';

  if (isTestEnvironment) {
    return <div data-testid={name}>{accordionContent}</div>;
  }

  return (
    <EditableAccordion
      name={name}
      renderAccordion={(props: Partial<IAccordionItemProps>) => {
        const { boxVariation, title, subTitle } = props;

        return (
          <AccordionCmp
            className={accordionClassName}
            boxVariation={boxVariation}>
            <AccordionItem
              key={title}
              id={title || ''}
              title={title || ''}
              subTitle={subTitle}>
              {accordionContent}
            </AccordionItem>
          </AccordionCmp>
        );
      }}
    />
  );
}
