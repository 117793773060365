import { useEffect } from 'react';

import useClocStore from '../../store';
import { IClocFormData, IClocState, IDynamicObj, IFinancialStatementsDocs, IValidationStatus } from '../../store/types';
import { debounce, getFormSteps } from '../jsUtils';

export interface IuseValidationStatus {
  key: keyof IClocFormData | keyof IValidationStatus;
  validationFn: (
    obj: IDynamicObj | IValidationStatus,
    region: string,
    financialDocs?: IFinancialStatementsDocs | undefined
  ) => { isValid: boolean; validationStatus: IDynamicObj };
  skipSub?: boolean;
}

const useValidationStatus: any = ({ key, validationFn, skipSub = false }: IuseValidationStatus) => {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);
  const updateValidationStatus = useClocStore((state) => (state as IClocState).updateValidationStatus);
  const updateClocFormValidity = useClocStore((state) => (state as IClocState).updateClocFormValidity);

  const region = useClocStore((state) => state.region);

  // update global validation status of all steps
  const checkClocFormValidity = (clocFormData: IClocFormData) => {
    const { validationKeys } = getFormSteps(region);
    let valid = true;
    const stepsStatus: any = {};

    for (const step of validationKeys) {
      if (step) {
        stepsStatus[step] = clocFormData[step as keyof IClocFormData].isValid;
        valid = !stepsStatus[step] ? false : valid;
      }
    }
    return { valid, stepsStatus };
  };

  function triggerValidation(clocFormData: IClocFormData) {
    const data = clocFormData[key];
    const { isValid, validationStatus } = validationFn(data, region, useClocStore.getState().financialStatementsDocs);

    updateValidationStatus(key, (obj: IValidationStatus) => {
      obj[key as keyof IValidationStatus] = { ...validationStatus };
    });

    updateFormData(key as keyof IClocFormData, (obj: IDynamicObj) => {
      obj.isValid = isValid;
    });

    const allStepsValid = checkClocFormValidity(clocFormData);
    const isReviewValid = allStepsValid.valid;
    const isSubmitValid = clocFormData['signAndSubmit'].isValid;

    allStepsValid.stepsStatus = { ...allStepsValid.stepsStatus, applicationReview: isReviewValid, signAndSubmit: isSubmitValid };

    updateClocFormValidity(allStepsValid);
    updateFormData('applicationReview', (obj: IDynamicObj) => {
      obj.isValid = isReviewValid;
    });
  }

  const debouncedTrigger = debounce(triggerValidation, 300);

  const manualTrigger = () => {
    debouncedTrigger(useClocStore.getState().clocFormData);
  };

  useEffect(() => {
    // trigger on mount
    debouncedTrigger(useClocStore.getState().clocFormData);

    if (skipSub) {
      return;
    }

    // subscribe to store updates without causing component rerender
    const unSub = useClocStore.subscribe(
      (state) => state.clocFormData,
      (clocFormData: any) => {
        debouncedTrigger(clocFormData);
      }
    );

    return function cleanup() {
      unSub();
    };
  }, []);

  return manualTrigger;
};

export default useValidationStatus;
