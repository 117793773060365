import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Divider } from '@fcamna/react-library';
import { useRef, useState } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { isValidEmail, isValidString, isValidUSPhone } from '../../../utils/jsUtils';
import Checkbox from '../../atoms/checkbox';
import InlineAlert from '../../atoms/inlineAlert';
import Input from '../../atoms/input';
import Text from '../../atoms/text';
import WithAddMore from '../../molecules/withAddMore';
import styles from './contactInfo.module.scss';

const PG_LIMIT = 6;

interface IPGForm {
  validate?: boolean;
  index: number;
  showLimitMsg: boolean;
  getBO: (index: number) => IDynamicObj;
  updateBO: (index: number, o: IDynamicObj) => void;
  aemPath: string;
}

const customValidations = {
  email: (val: string) => {
    if (!isValidEmail(val)) {
      return 'invalid';
    }

    return '';
  },
  phone: (val: string) => {
    if (!isValidUSPhone(val)) {
      return 'invalid';
    }

    return '';
  }
};

export const getFormattedPrimaryNames = (primaryFN = '', primaryLN = '', primaryPhone = '', primaryEmail = '') => {
  const formattedPhone = `${primaryFN || primaryLN ? ',' : ''} ${primaryPhone}`;
  const phone = primaryPhone ? formattedPhone : '';
  const formattedEmail = `${primaryFN || primaryLN || phone ? ',' : ''} ${primaryEmail}`;
  const email = primaryEmail ? formattedEmail : '';
  return `${primaryFN} ${primaryLN}${phone}${email}`;
};

const PGForm = ({ validate = false, index, showLimitMsg, getBO, updateBO, aemPath }: IPGForm) => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const [pg, setPg] = useState(getBO(index));

  const primaryFN = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.firstName);
  const primaryLN = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.lastName);
  const primaryPhone = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.phoneNum);
  const primaryEmail = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.email);

  const updatePG = (o: IDynamicObj) => {
    setPg(o);
    updateBO(index, o);
  };

  const firstOwnerCheckHandler = ({ value }: IDynamicObj) => {
    const foCheckBox = value as Record<string, string | boolean>[];
    const newVal: IDynamicObj = foCheckBox[0].checked
      ? { isPrimaryContact: true, pgFirstName: primaryFN, pgLastName: primaryLN, pgPhone: primaryPhone, pgEmail: primaryEmail }
      : { isPrimaryContact: false };
    updatePG(newVal);
  };

  const changeHandler = ({ name, value }: IDynamicObj) => {
    const o = { ...pg, [name as string]: value };
    updatePG(o);
  };

  if (!pg) {
    return null;
  }

  const { isPrimaryContact, pgFirstName, pgLastName, pgPhone, pgEmail } = pg;

  const isPGValid = isPrimaryContact
    ? isValidString(primaryFN) && isValidString(primaryLN) && isValidUSPhone(primaryPhone as string) && isValidEmail(primaryEmail as string)
    : isValidString(pgFirstName) && isValidString(pgLastName) && isValidUSPhone(pgPhone as string) && isValidEmail(pgEmail as string);

  return (
    <div className={styles.form}>
      <div className='cf-field-title'>
        <Text
          className={styles.pgHeading}
          name={`pg-heading`}
          replacements={{ 'num': `${index + 1}` }}
        />
      </div>
      {index === 0 && (
        <div className='cf-field'>
          <Checkbox
            defaultValue={{ 0: isPrimaryContact as boolean }}
            name="pgIsSameAsPrimaryContact"
            changeHandler={firstOwnerCheckHandler}
            validate={validate}
            pagePath={aemPath}
          />
        </div>
      )}

      {isPrimaryContact ? (
        <p className={styles.primaryContact}>
          {getFormattedPrimaryNames(primaryFN as string, primaryLN as string, primaryPhone as string, primaryEmail as string)}
        </p>
      ) : (
        <>
          <Input
            type="text"
            name="pgFirstName"
            dataTestId="pgFirstName"
            defaultValue={pgFirstName as string}
            changeHandler={changeHandler}
            validate={validate}
            required
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="pgLastName"
            dataTestId="pgLastName"
            defaultValue={pgLastName as string}
            changeHandler={changeHandler}
            validate={validate}
            required
            showBlankSpaceValidation
          />
          <Input
            type="tel"
            name="pgPhone"
            dataTestId="pgPhone"
            defaultValue={pgPhone as string}
            changeHandler={changeHandler}
            validate={validate}
            required
            customValidation={customValidations.phone}
            customErrorKeys={['invalid']}
            inputTypeFormat="tel"
          />
          <Input
            type="email"
            name="pgEmail"
            dataTestId="pgEmail"
            defaultValue={pgEmail as string}
            changeHandler={changeHandler}
            validate={validate}
            required
            customValidation={customValidations.email}
            customErrorKeys={['invalid']}
          />
        </>
      )}
      {(isAuthorView || (showLimitMsg && isPGValid)) && <InlineAlert name={'pg-add-success'} />}
    </div>
  );
};

export default function PersonalGuarantors({ validate = false, aemPath = '' }: Readonly<{ validate?: boolean; aemPath?: string }>) {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);
  const isPGProvided = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.isPGProvided);
  const personalGuarantors = useClocStore(
    (state) => (state as IClocState).clocFormData.contactInformation.personalGuarantors
  ) as IDynamicObj[];
  const pgLength = useRef(personalGuarantors?.length);

  const isAuthorView = AuthoringUtils.isInEditor();

  const addCallback = () => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.personalGuarantors as Array<IDynamicObj>).push({ pgFirstName: '' });
    });
  };

  const removeCallback = (idx: number) => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.personalGuarantors as Array<IDynamicObj>).splice(idx, 1);
    });
    pgLength.current -= 1;
  };

  const getBO = (index: number) => (isAuthorView ? { pgFirstName: '' } : personalGuarantors?.[index]);

  const updateBO = (index: number, bObj: IDynamicObj) => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.personalGuarantors as Array<IDynamicObj>)[index] = bObj;
    });
  };

  const pgExist = isPGProvided === 'Yes';

  return (
    <>
      {(isAuthorView || pgExist) && (
        <WithAddMore
          id="pg"
          initLength={isAuthorView ? 1 : personalGuarantors?.length}
          renderComp={(index: number, totalElems: number = 0) => (
            <>
              <PGForm
                validate={validate && index < pgLength.current}
                index={index}
                showLimitMsg={index === totalElems - 1}
                getBO={getBO}
                updateBO={updateBO}
                aemPath={aemPath}
              />
              {index < totalElems - 1 ? <Divider style={{ marginTop: '32px' }} /> : null}
            </>
          )}
          addCallback={addCallback}
          removeCallback={removeCallback}
          maxLimit={PG_LIMIT}
        />
      )}
      {(isAuthorView || personalGuarantors?.length === PG_LIMIT) && (
        <InlineAlert
          name="pg-limit"
          titleOnly
          className={styles.pgLimit}
        />
      )}
    </>
  );
}
