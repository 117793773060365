import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Divider } from '@fcamna/react-library';
import { useEffect, useRef, useState } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import Checkbox from '../../atoms/checkbox';
import FieldError from '../../atoms/fieldError';
import Input from '../../atoms/input';
import Text from '../../atoms/text';
import WithAddMore from '../../molecules/withAddMore';
import styles from './contactInfo.module.scss';

const BO_LIMIT = 25;

interface IBusinessOwnerForm {
  validate?: boolean;
  index: number;
  getBO: (index: number) => IDynamicObj;
  updateBO: (index: number, o: IDynamicObj) => void;
  aemPath: string;
}

const getOwnership = (val: string) => {
  if (!val) {
    return 0;
  }

  return Number(parseFloat(val).toFixed(2));
};

const getFormattedPrimaryName = (primaryFN = '', primaryLN = '', primaryTitle = '') => {
  const formattedTitle = `${primaryFN || primaryLN ? ',' : ''} ${primaryTitle}`;
  const title = primaryTitle ? formattedTitle : '';
  return `${primaryFN} ${primaryLN}${title}`;
};

const BusinessOwnerForm = ({ validate = false, index, getBO, updateBO, aemPath }: IBusinessOwnerForm) => {
  const [businessOwner, setBusinessOwner] = useState<IDynamicObj>(getBO(index));

  const primaryFN = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.firstName);
  const primaryLN = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.lastName);
  const primaryTitle = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.title);

  const updateBusinessOwner = (o: IDynamicObj) => {
    setBusinessOwner(o);
    updateBO(index, o);
  };

  const isBusinessOwnerCheckHandler = ({ value }: IDynamicObj) => {
    const boCheckBox = value as Record<string, string | boolean>[];
    const o = { ...businessOwner, isBusinessOwner: boCheckBox[0].checked };
    updateBusinessOwner(o);
  };

  const firstOwnerCheckHandler = ({ value }: IDynamicObj) => {
    const foCheckBox = value as Record<string, string | boolean>[];
    const newVal: IDynamicObj = foCheckBox[0].checked
      ? { isPrimaryContact: true, boFirstName: primaryFN, boLastName: primaryLN, boTitle: primaryTitle }
      : { isPrimaryContact: false, boFirstName: '', boLastName: '', boTitle: '' };
    updateBusinessOwner({ ...businessOwner, ...newVal });
  };

  const changeHandler = ({ name, value }: IDynamicObj) => {
    const val = name === 'ownership' ? getOwnership(value as string) : value;
    const o = { ...businessOwner, [name as string]: val };
    updateBusinessOwner(o);
  };

  if (!businessOwner) {
    return null;
  }

  const { isPrimaryContact, isBusinessOwner, boFirstName, boLastName, boTitle, businessName, ownership } = businessOwner;

  return (
    <div className={styles.form}>
      <Text
        name="bo-add-heading"
        replacements={{ 'num': `${index + 1}` }}
      />

      {index === 0 && !isBusinessOwner && (
        <div className={styles.check}>
          <Checkbox
            defaultValue={{ 0: isPrimaryContact as boolean }}
            name="isSameAsPrimaryContact"
            changeHandler={firstOwnerCheckHandler}
            validate={validate}
            pagePath={aemPath}
          />
        </div>
      )}

      {isPrimaryContact ? (
        <p className={styles.primaryContact}>{getFormattedPrimaryName(primaryFN as string, primaryLN as string, primaryTitle as string)}</p>
      ) : (
        <>
          <div className={styles.check}>
            <Checkbox
              defaultValue={{ 0: isBusinessOwner as boolean }}
              name="isOwnerABusiness"
              changeHandler={isBusinessOwnerCheckHandler}
              validate={validate}
              pagePath={aemPath}
            />
          </div>
          {!isBusinessOwner ? (
            <>
              <Input
                type="text"
                name="boFirstName"
                id={`boFirstName-${index}`}
                dataTestId="boFirstName"
                defaultValue={boFirstName as string}
                changeHandler={changeHandler}
                validate={validate}
                required
                showBlankSpaceValidation
              />
              <Input
                type="text"
                name="boLastName"
                dataTestId="boLastName"
                defaultValue={boLastName as string}
                changeHandler={changeHandler}
                validate={validate}
                required
                showBlankSpaceValidation
              />
              <Input
                type="text"
                name="boTitle"
                dataTestId="boTitle"
                defaultValue={boTitle as string}
                changeHandler={changeHandler}
                validate={validate}
                required
                showBlankSpaceValidation
              />
              {AuthoringUtils.isInEditor() && (
                <Input
                  type="text"
                  name="businessName"
                  defaultValue={businessName as string}
                  changeHandler={changeHandler}
                  validate={validate}
                  required
                  showBlankSpaceValidation
                />
              )}
            </>
          ) : (
            <Input
              type="text"
              name="businessName"
              dataTestId="businessName"
              defaultValue={businessName as string}
              changeHandler={changeHandler}
              validate={validate}
              required
              showBlankSpaceValidation
            />
          )}
        </>
      )}
      <Input
        type="text"
        name="ownership"
        dataTestId="ownership"
        defaultValue={ownership as number}
        changeHandler={changeHandler}
        validate={validate}
        required
        inputTypeFormat="numeric"
      />
    </div>
  );
};

export default function BusinessOwners({ validate = false, aemPath = '' }: Readonly<{ validate?: boolean; aemPath?: string }>) {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);

  const businessOwners = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.businessOwners) as IDynamicObj[];
  const boLength = useRef(businessOwners?.length);

  const [error, setError] = useState(false);

  const addCallback = () => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.businessOwners as Array<IDynamicObj>).push({ isBusinessOwner: false });
    });
    checkTotalOwnershipErr(businessOwners);
  };

  const removeCallback = (idx: number) => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.businessOwners as Array<IDynamicObj>).splice(idx, 1);
    });
    checkTotalOwnershipErr(businessOwners);
    boLength.current -= 1;
  };

  const getBO = (index: number) => businessOwners?.[index];

  const updateBO = (index: number, bObj: IDynamicObj) => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      (obj.businessOwners as Array<IDynamicObj>)[index] = bObj;
    });
  };

  const checkTotalOwnershipErr = (ownersList: IDynamicObj[]) => {
    let totalOwnership = 0;
    let totalError = false;
    ownersList.forEach(({ ownership }: IDynamicObj) => {
      if (ownership !== undefined) {
        totalOwnership += ownership as number;
      }
    });

    totalOwnership = getOwnership(String(totalOwnership));
    if (totalOwnership === 0) {
      if (validate) {
        setError(true);
      }
    } else if (totalOwnership === 100) {
      ownersList.forEach(({ ownership }: IDynamicObj) => {
        if (ownership === undefined) {
          totalError = true;
        }
      });
      setError(totalError);
    } else if (totalOwnership >= 0 && totalOwnership !== 100) {
      setError(true);
    }
  };

  useEffect(() => {
    checkTotalOwnershipErr(businessOwners);

    // subscribe to store updates without causing component rerender
    const unSub = useClocStore.subscribe((state) => {
      checkTotalOwnershipErr((state as IClocState).clocFormData.contactInformation.businessOwners as IDynamicObj[]);
    });

    return function cleanup() {
      unSub();
    };
  }, []);

  const isAuthorView = AuthoringUtils.isInEditor();
  return (
    <>
      <WithAddMore
        id="bo"
        initLength={businessOwners.length}
        renderComp={(index: number, totalElems: number = 0) => (
          <>
            <BusinessOwnerForm
              validate={validate && index < boLength.current}
              index={index}
              getBO={getBO}
              updateBO={updateBO}
              aemPath={aemPath}
            />
            {index < totalElems - 1 ? <Divider style={{ marginTop: '32px' }} /> : null}
          </>
        )}
        addCallback={addCallback}
        removeCallback={removeCallback}
        maxLimit={BO_LIMIT}
      />
      {(isAuthorView || error) && (
        <FieldError
          className={styles.boCALimit}
          name="bo-total-error"
        />
      )}
    </>
  );
}
