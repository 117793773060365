import { IDynamicObj, IValidationStatus } from '../../store/types';
import { formatPhoneNumber, removeSymbols, toNumber } from '../../utils/jsUtils';
import {
  BillingInformation,
  BusinessContactInformationUS,
  BusinessInformationCA,
  BusinessInformationUS,
  CCCDAgreement,
  ContactInformationCA,
  FinancialStatements,
  FleetInformationCA,
  FleetInformationUS,
  Loan,
  Owner,
  PersonalGuarantor,
  SignAndSubmitCA,
  SignAndSubmitUS,
  SubmitApiDataCA,
  SubmitApiDataUS
} from './apiTypes';
import {
  getFordVehiclePurchaseLocation,
  getFormattedDate,
  getLoans,
  getLocalDate,
  getOwners,
  getPersonalGuarantor,
  parseYesNo
} from './utils';

const transformBusinessInfo = (businessFormData: IDynamicObj): BusinessInformationUS | BusinessInformationCA => {
  const transformedBusinessInfo = {
    businessName: businessFormData.businessName as string,
    doingBusinessAs: businessFormData.doingBusinessAs as string,
    website: businessFormData.website as string,
    address: {
      street: businessFormData.street as string,
      city: businessFormData.city as string
    },
    garagingAddress: {
      street: businessFormData.garageStreet as string,
      city: businessFormData.garageCity as string
    }
  };

  if ('federalTaxId' in businessFormData) {
    // US
    return {
      ...transformedBusinessInfo,
      federalTaxId: businessFormData?.federalTaxId ? (businessFormData.federalTaxId as string).replace(/-/g, '') : '',
      organizationState: businessFormData.organizationState as string,
      address: {
        ...transformedBusinessInfo.address,
        state: businessFormData.state as string,
        zip: businessFormData.zip as string
      },
      garagingAddress: {
        ...transformedBusinessInfo.garagingAddress,
        state: businessFormData.garageState as string,
        zip: businessFormData.garageZip as string
      }
    };
  }

  // Canada
  return {
    ...transformedBusinessInfo,
    gstHstRegistration: businessFormData.gstHstRegistration as string,
    incorporationDate: businessFormData.incorporationDate ? getFormattedDate(businessFormData.incorporationDate as IDynamicObj) : undefined,
    doingBusinessAs: businessFormData.doingBusinessAs as string,
    incorporationProvince: businessFormData.incorporationProvince as string,
    address: {
      ...transformedBusinessInfo.address,
      province: businessFormData.province as string,
      postal: businessFormData.postalCode as string
    },
    garagingAddress: {
      ...transformedBusinessInfo.garagingAddress,
      province: businessFormData.garageProvince as string,
      postal: businessFormData.garagePostalCode as string
    }
  };
};

const transformContactInfo = (contactFormData: IDynamicObj, region: string): BusinessContactInformationUS | ContactInformationCA => {
  const owners: Owner[] = getOwners(contactFormData);

  const transformedData: {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
    secondaryFirstName?: string | null;
    secondaryLastName?: string | null;
    secondaryTitle?: string | null;
    secondaryPhone?: string | null;
    secondaryEmail?: string | null;
    owners: Owner[];
  } = {
    firstName: contactFormData.firstName as string,
    lastName: contactFormData.lastName as string,
    title: contactFormData.title as string,
    phone: formatPhoneNumber(removeSymbols(contactFormData.phoneNum as string)),
    email: contactFormData.email as string,
    secondaryFirstName: contactFormData.secondaryFirstName ? (contactFormData.secondaryFirstName as string) : null,
    secondaryLastName: contactFormData.secondaryLastName ? (contactFormData.secondaryLastName as string) : null,
    secondaryTitle: contactFormData.secondaryTitle ? (contactFormData.secondaryTitle as string) : null,
    secondaryPhone: contactFormData.secondaryPhoneNum
      ? formatPhoneNumber(removeSymbols(contactFormData.secondaryPhoneNum as string))
      : null,
    secondaryEmail: contactFormData.secondaryEmail ? (contactFormData.secondaryEmail as string) : null,
    owners: owners
  };

  if (region === 'ca') {
    delete transformedData.secondaryFirstName;
    delete transformedData.secondaryLastName;
    delete transformedData.secondaryTitle;
    delete transformedData.secondaryPhone;
    delete transformedData.secondaryEmail;
  }

  if ('isPGProvided' in contactFormData && region === 'us') {
    const personalGuarantors: PersonalGuarantor[] = getPersonalGuarantor(contactFormData);
    return {
      ...transformedData,
      isWillingToProvidePersonalGuarantor:
        contactFormData?.isPGProvided !== undefined ? parseYesNo(contactFormData?.isPGProvided as string) : undefined,
      personalGuarantors:
        contactFormData?.isPGProvided !== undefined && parseYesNo(contactFormData?.isPGProvided as string) ? personalGuarantors : undefined
    };
  }

  // Canada
  return transformedData;
};

const transformFleetInfo = (fleetFormData: IDynamicObj, region: string): FleetInformationUS | FleetInformationCA => {
  const loans: Loan[] = getLoans(fleetFormData);

  const transformedFleetInfo = {
    primaryRevenueSource: fleetFormData.primaryRevenueSource as string,
    doesBusinessHaveLineOfCredit: parseYesNo(fleetFormData.doesBusinessHaveLineOfCredit as string),
    addEquipment: parseYesNo(fleetFormData.addEquipment as string),
    numberOfEmployees: toNumber(removeSymbols(fleetFormData.numberOfEmployees as string)),
    projectedFleetSize: toNumber(removeSymbols(fleetFormData.projectedFleetSize as string)),
    replacementUnits: fleetFormData.replacementUnits as number,
    additionalUnits: fleetFormData.additionalUnits as number,
    fordVehiclePurchaseLocation: getFordVehiclePurchaseLocation(fleetFormData),
    requestedLineAmount: toNumber(removeSymbols(fleetFormData.requestedLineAmount as string)),
    loans: loans,
    addEquipmentDescription: fleetFormData.addEquipmentDescription as string,
    relatedBusinessDescription: fleetFormData.relatedBusinessDescription as string,
    willRelatedBusinessRequireCredit: parseYesNo(fleetFormData.willRelatedBusinessRequireCredit as string),
    intendedUseOfVehicles: fleetFormData.intendedUseOfVehicle as string,
    hasRelatedBusinesses: parseYesNo(fleetFormData.hasRelatedBusiness as string),
    relatedBusinessNames: fleetFormData.relatedBusinessName as string,
    willRelatedEntitiesSubleaseVehicles: parseYesNo(fleetFormData.willRelatedBusinessSubleaseVehicles as string)
  };

  if (region === 'ca') {
    return {
      ...transformedFleetInfo,
      annualKilometersPerUnit: toNumber(removeSymbols(fleetFormData.annualKilometersPerUnit as string))
    };
  }

  // US
  return {
    ...transformedFleetInfo,
    annualMilesPerUnit: toNumber(removeSymbols(fleetFormData.annualMilesPerUnit as string))
  };
};

// Billing Information
const transformBillingInfo = (billingFormData: IDynamicObj): BillingInformation => {
  return {
    isEnrolledInCombinedBilling: parseYesNo(billingFormData.isEnrolledInCombinedBilling as string),
    isTotalRevenueExceedsOneMillion: parseYesNo(billingFormData.isTotalRevenueExceedsOneMillion as string),
    isInterestedInPaymentDate: parseYesNo(billingFormData.isInterestedInPaymentDate as string),
    preferredPaymentDate: billingFormData.paymentDueDate as string
  };
};

// CCCD
const transformCCCDAgreement = (cccdFormData: IDynamicObj): CCCDAgreement => {
  return {
    firstName: cccdFormData.firstName as string,
    lastName: cccdFormData.lastName as string,
    title: cccdFormData.title as string
  };
};

// Financial Statements
const transformFinancialStatements = (financialFormData: IDynamicObj): FinancialStatements => {
  return {
    uploads: {
      mostRecent: {
        documentType: financialFormData.mostRecentDocName as string,
        uploaded: financialFormData.mostRecentDocName !== 'I do not have the above types of financial statements'
      },
      secondMostRecent: {
        documentType: financialFormData?.secondRecentDocName
          ? (financialFormData.secondRecentDocName as string)
          : 'I do not have the above types of financial statements',
        uploaded:
          Boolean(financialFormData.secondRecentDocName) &&
          financialFormData.secondRecentDocName !== 'I do not have the above types of financial statements'
      },
      thirdMostRecent: {
        documentType: financialFormData?.thirdRecentDocName
          ? (financialFormData.thirdRecentDocName as string)
          : 'I do not have the above types of financial statements',
        uploaded:
          Boolean(financialFormData.thirdRecentDocName) &&
          financialFormData.thirdRecentDocName !== 'I do not have the above types of financial statements'
      }
    }
  };
};

// Sign and Submit
const transformSignAndSubmit = (sasFormData: IDynamicObj, region: string): SignAndSubmitUS | SignAndSubmitCA => {
  const transformedSignAndSubmit = {
    firstName: sasFormData.firstName as string,
    lastName: sasFormData.lastName as string,
    title: sasFormData.title as string
  };

  if (region === 'ca') {
    return {
      ...transformedSignAndSubmit,
      marketingAccept: sasFormData.marketingComm as boolean
    };
  }

  // US
  return transformedSignAndSubmit;
};

// Main Transform functions
export const transformDataUS = (formDataUS: IValidationStatus, isCredit?: boolean): SubmitApiDataUS => {
  const params = new URLSearchParams(window.location.search);
  const {
    businessInformation,
    contactInformation,
    fleetInformation,
    billingInformation,
    financialStatements,
    cccdAgreement,
    signAndSubmit
  } = formDataUS;
  const region = 'us';
  const locale = 'en-us';
  const origin = isCredit ? 'FC' : 'FP';

  return {
    businessInformation: transformBusinessInfo(businessInformation) as BusinessInformationUS,
    businessContactInformation: transformContactInfo(contactInformation, region) as BusinessContactInformationUS,
    fleetInformation: transformFleetInfo(fleetInformation, region) as FleetInformationUS,
    billingInformation: transformBillingInfo(billingInformation),
    financialStatements: transformFinancialStatements(financialStatements),
    applicant: transformCCCDAgreement(cccdAgreement),
    agreement: transformSignAndSubmit(signAndSubmit, region),
    submissionDate: getLocalDate().toString(),
    isTestApplication: params.get('test_application') === 'true',
    clocInvitation: params.get('screener') === 'clocApplication',
    locale: locale,
    origin: origin
  };
};

export const transformDataCA = (formDataCA: IValidationStatus, locale: 'en-ca' | 'fr-ca', isCredit?: boolean): SubmitApiDataCA => {
  const { businessInformation, contactInformation, fleetInformation, signAndSubmit } = formDataCA;
  const region = 'ca';
  const origin = isCredit ? 'FC' : 'FP';
  const params = new URLSearchParams(window.location.search);

  return {
    businessInformation: transformBusinessInfo(businessInformation) as BusinessInformationCA,
    businessContactInformation: transformContactInfo(contactInformation, region) as ContactInformationCA,
    fleetInformation: transformFleetInfo(fleetInformation, region) as FleetInformationCA,
    applicant: transformSignAndSubmit(signAndSubmit, region) as SignAndSubmitCA,
    submissionDate: getLocalDate(),
    isTestApplication: params.get('test_application') === 'true',
    locale: locale,
    origin: origin
  };
};
