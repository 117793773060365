import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { ReactNode, useEffect, useState } from 'react';

import styles from './layout.module.scss';

export interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  const [opacity, setOpacity] = useState(0);

  // some delay introduced in page visibility to handle CLS
  // CLS impacts lighthouse scores, unavoidable as Editable components load content from AEM
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 350);
  }, []);

  return (
    <ThemeProvider
      themeName={ThemeName.FORD_PRO}
      mode={Mode.LIGHT_MODE}
      productName="CLOC">
      <div
        className={`${styles.rootContainer} app-container`}
        style={{ opacity }}>
        <div className={styles.layout}>{children}</div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
