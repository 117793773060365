/**
 * This hook loads one time at app level
 * Set app locale - en, fr
 * Set app region - us, ca
 * Set app type - credit, pro
 * Set return url for exit points
 * Init local storage connection
 */

import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect } from 'react';

import useClocStore from '../../store';
import { getSessionStorage } from '../jsUtils';
import useLocalStorageSync from '../useLocalStorageSync';

// Read env variables. Can't read with dynamic keys, webpack_react build limitation
const variants: Record<string, string | undefined> = {
  CLOC_CREDIT_EN_US: process.env.CLOC_CREDIT_EN_US,
  CLOC_CREDIT_EN_CA: process.env.CLOC_CREDIT_EN_CA,
  CLOC_CREDIT_FR_CA: process.env.CLOC_CREDIT_FR_CA,
  CLOC_PRO_EN_US: process.env.CLOC_PRO_EN_US,
  CLOC_PRO_EN_CA: process.env.CLOC_PRO_EN_CA,
  CLOC_PRO_FR_CA: process.env.CLOC_PRO_FR_CA
};

// Author view
const authorVariants = {
  CLOC_PRO_EN_US: process.env.AEM_BASE_PATH_EN_US,
  CLOC_PRO_EN_CA: process.env.AEM_BASE_PATH_EN_CA,
  CLOC_PRO_FR_CA: process.env.AEM_BASE_PATH_FR_CA
};

const returnUrls: Record<string, string | undefined> = {
  CLOC_RETURN_CREDIT_EN_US: process.env.CLOC_RETURN_CREDIT_EN_US,
  CLOC_RETURN_CREDIT_EN_CA: process.env.CLOC_RETURN_CREDIT_EN_CA,
  CLOC_RETURN_CREDIT_FR_CA: process.env.CLOC_RETURN_CREDIT_FR_CA,
  CLOC_RETURN_PRO_EN_US: process.env.CLOC_RETURN_PRO_EN_US,
  CLOC_RETURN_PRO_EN_CA: process.env.CLOC_RETURN_PRO_EN_CA,
  CLOC_RETURN_PRO_FR_CA: process.env.CLOC_RETURN_PRO_FR_CA
};

const useGlobalConfig = () => {
  const isAuthorView = AuthoringUtils.isInEditor();

  const { loadPrevState: restoreLastState } = useLocalStorageSync({});
  const updateGlobalConfig = useClocStore((state) => state.updateGlobalConfig);

  const updateStore = (matchVariant: string) => {
    const parts = matchVariant.split('_');
    const appType = parts[1].toLowerCase();
    const locale = parts[2].toLowerCase();
    const region = parts[3].toLowerCase();
    const homeKey = matchVariant.replace('CLOC_', 'CLOC_RETURN_');
    const homePath = returnUrls[homeKey];
    updateGlobalConfig({ appType, locale, region, homePath });

    const lsKey = `cloc_state_${appType}_${locale}_${region}`;
    restoreLastState(lsKey);
  };

  useEffect(() => {
    try {
      // Use url params as first choice ( to force a specific variant)
      const params = new URLSearchParams(window.location.search);
      const qLocale = params.get('locale');
      if (qLocale) {
        updateStore(qLocale);
        return;
      }

      // Use session storage key if available
      const isOverride = getSessionStorage('CLOC_LOCALE') as string;
      if (isOverride) {
        updateStore(isOverride);
        return;
      }

      // Figure out locale via url
      const href = window.location.href;
      const vr = isAuthorView ? authorVariants : variants;
      let matchVariant = '';

      Object.entries(vr).forEach(([k, v]) => {
        if (href.match(new RegExp(v as string))) {
          matchVariant = k;
        }
      });

      updateStore(matchVariant);
    } catch (error) {
      // set default if error
      console.log('>>> Locale parse error. Launching US_EN version as default.');
      updateStore('CLOC_PRO_EN_US');
    }
  }, []);
};

export default useGlobalConfig;
