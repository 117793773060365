import { ReactNode, useEffect } from 'react';

import useClocStore from '../../../store';
import { analyticsOnLoadHelper } from './AnalyticsHelper';
import { IAnalyticsDetails } from './types';


let analyticsEventQueue: Array<IAnalyticsDetails> = [];

export const fireAnalyticsLoad = ({ pageName, appId, pageSpecificDigitalData }: IAnalyticsDetails) => {
  const event = { pageName, appId, pageSpecificDigitalData };
  if (!window._satellite) {
    analyticsEventQueue.push(event);
    return;
  }
  analyticsOnLoadHelper(event);
};

export const OneTrustCookies = ({ children }: { children: ReactNode }) => {
  const appType = useClocStore((state) => state.appType);
  const isFordPro = appType === 'pro';

  useEffect(() => {
    let interval: number | undefined = undefined;
    let fpgInterval: number | undefined = undefined;

    if (isFordPro) {
      fpgInterval = window.setInterval(() => {
        if (window._satellite) {
          // empty event queue
          analyticsEventQueue.forEach((event: IAnalyticsDetails) => {
            analyticsOnLoadHelper(event);
          });
          window.clearInterval(fpgInterval);
          analyticsEventQueue = [];
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
      if (fpgInterval) {
        window.clearInterval(fpgInterval);
      }
    };
  }, []);

  return <>{children}</>;
};

export default OneTrustCookies;
