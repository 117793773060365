import isPropValid from '@emotion/is-prop-valid';
import { ReactNode, useEffect, useState } from 'react';
import { StyleSheetManager } from 'styled-components';

import styles from './layout.module.scss';

export interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  const [opacity, setOpacity] = useState(0);

  // some delay introduced in page visibility to handle CLS
  // CLS impacts lighthouse scores, unavoidable as Editable components load content from AEM
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 350);
  }, []);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        className={`${styles.rootContainer} app-container`}
        style={{ opacity }}>
        <div className={styles.layout}>{children}</div>
      </div>
    </StyleSheetManager>
  );
};

function shouldForwardProp(propName: any, target: any) {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }

  return true;
}

export default Layout;
