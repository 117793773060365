import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableCheckboxGroup, IEditableCheckbox } from '@fcamna/aem-library';
import { Checkbox, CheckboxList } from '@fcamna/react-library';
import { useEffect, useRef, useState } from 'react';

import { useModel } from '../../../utils/aem/aemHOC';
import styles from './checkbox.module.scss';

export interface CheckboxProps {
  id?: string;
  defaultValue?: Record<string, boolean>;
  value?: Record<string, boolean>;
  name: string;
  changeHandler: (o: Record<string, any>) => void;
  validate?: boolean;
  required?: boolean;
  pagePath?: string;
  className?: string;
}

/*
custom valildation not required, checkbox are either true or false.
*/
const checkValidityInput = (elems: NodeListOf<HTMLInputElement>, required?: boolean) => {
  if (!required) {
    return false;
  }

  let isOneChecked = false;
  elems.forEach((e: HTMLInputElement) => {
    if (e.checked) {
      isOneChecked = true;
    }
  });

  return !isOneChecked;
};

const CheckBox = ({
  changeHandler,
  defaultValue,
  value = undefined,
  name,
  validate = false,
  pagePath = '',
  required = false,
  className
}: CheckboxProps) => {
  const checkDivRef = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState<Record<string, boolean> | undefined>(defaultValue);

  const [isInvalid, setIsInvalid] = useState(false);

  const { parsedModel } = useModel({ pagePath });

  useEffect(() => {
    if (validate && checkDivRef.current) {
      triggerValidation();
    }
  }, [validate, checkDivRef.current]);

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(val)) {
      setVal(value);
    }
  }, [value]);

  const triggerValidation = () => {
    const checkboxList = checkDivRef.current?.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement>;
    const invalid = checkValidityInput(checkboxList, required);
    setIsInvalid(invalid);
    return invalid;
  };

  const onChangeHandler = ({ index, checked, items }: { index: number; checked: boolean; items: Array<Record<string, string>> }) => {
    triggerValidation();
    const prevVal = val || {};
    const newVal: Record<string, boolean> = { ...prevVal, [index]: checked };
    setVal(newVal);

    changeHandler({ name, value: items.map((i: Record<string, string>, idx: number) => ({ ...i, checked: newVal[idx] })), isInvalid });
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>, index: number, items: Array<Record<string, string>>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      const checked = !val?.[index];
      onChangeHandler({ index, checked, items });
    }
  };

  if (AuthoringUtils.isInEditor()) {
    return <EditableCheckboxGroup name={name} />;
  }

  if (!parsedModel?.[`checkbox_${name}`]) {
    return null;
  }

  const { title, helperText, items, ...otherGroupProps } = parsedModel[`checkbox_${name}`];

  return (
    <div
      ref={checkDivRef}
      className={`${className} ${styles.checkboxList}`}>
      <CheckboxList
        labelProps={{ labelProp: { label: title } }}
        helperTextProp={{
          defaultProp: {
            helperText: isInvalid ? helperText : ''
          }
        }}
        inputState={isInvalid ? 'error' : undefined}
        isIntermediate={false}
        {...otherGroupProps}>
        {items?.map(({ label, value, helpText, isDisabled, ...otherItemProps }: Partial<IEditableCheckbox>, index: number) => {
          return (
            <Checkbox
              label={label as string}
              checkboxValue={val?.[index] ? 1 : 0}
              checked={val?.[index] || false}
              helperText={helpText}
              isIntermediate={false}
              key={value}
              error={isInvalid}
              disabled={Boolean(isDisabled)}
              onChange={(e) => onChangeHandler({ index, checked: e.currentTarget.checked, items })}
              onKeyDown={(e) => onKeyDownHandler(e, index, items)}
              {...otherItemProps}
            />
          );
        })}
      </CheckboxList>
    </div>
  );
};

export default CheckBox;
