import { ReactElement } from 'react';

import { isMobileMedia } from '../../../utils/jsUtils';
import styles from './btnGroup.module.scss';

export interface IBtnGroup {
  btns: ReactElement[];
  className?: string;
}

const BtnGroup = ({ btns, className = '' }: IBtnGroup) => {
  const isMobile = isMobileMedia();
  const renderNodes = isMobile ? [...btns].reverse() : btns;

  return <div className={`${styles.btnGroup} ${className}`}>{renderNodes.map((elem: ReactElement) => elem)}</div>;
};

export default BtnGroup;
