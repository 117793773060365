import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { lazy, Suspense } from 'react';

import AEMRoute from '../../../utils/aem/aemRoute';
import { getAEMPath } from '../../../utils/jsUtils';
import SpinnerComp from '../../atoms/spinner';
import BusinessInfo from '../../organisms/businessInfo';
import ClocForm from '../../templates/clocForm';

const ContactInfo = lazy(() => import('../../organisms/contactInfo'));
const FleetInfo = lazy(() => import('../../organisms/fleetInfo'));
const BillingInfo = lazy(() => import('../../organisms/billingInfo'));
const CCCD = lazy(() => import('../../organisms/cccd'));
const FS = lazy(() => import('../../organisms/financialStatements'));
const Review = lazy(() => import('../../organisms/applicationReview'));
const Submit = lazy(() => import('../../organisms/signAndSubmit'));
const Survey = lazy(() => import('../../templates/survey'));
const Screener = lazy(() => import('../../templates/screener'));

const PATH_MAP: Record<string, any> = {
  screener: Screener,
  cloc: ClocForm,
  businessInformation: BusinessInfo,
  contactInformation: ContactInfo,
  fleetInformation: FleetInfo,
  billingInformation: BillingInfo,
  cccdAgreement: CCCD,
  financialStatements: FS,
  applicationReview: Review,
  signAndSubmit: Submit,
  survey: Survey
};

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '24px'
      }}>
      <SpinnerComp />
    </div>
  );
};

const CompLoader = ({ path }: { path: string }) => {
  const aemPath = getAEMPath(path);
  const Comp = PATH_MAP[path];
  const isAuthorView = AuthoringUtils.isInEditor();

  return (
    <AEMRoute route={aemPath}>
      <Suspense fallback={<Loader />}>
        {Boolean(isAuthorView) && (
          <h1>Note: This is lazy loaded React component. If author options are not working, try toggle between preview mode & edit mode</h1>
        )}
        <Comp />
      </Suspense>
    </AEMRoute>
  );
};

export default CompLoader;
