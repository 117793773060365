import { IDynamicObj } from '../../store/types';
import { formatPhoneNumber, removeSymbols, toNumber } from '../../utils/jsUtils';
import { Loan, Owner, PersonalGuarantor } from './apiTypes';

export const parseYesNo = (value: string): boolean => {
  return value === 'Yes';
};

export const getFormattedDate = (dateObj: IDynamicObj) => {
  const date = new Date(dateObj.year as number, (dateObj.month as number) - 1, dateObj.day as number);
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;

  return date.getFullYear() + '-' + month + '-' + day;
};

export function getOwners(contactInformation: IDynamicObj): Owner[] {
  const owners = contactInformation.businessOwners as any;
  const formattedOwners: Owner[] = [];
  owners.forEach((owner: any) => {
    let formattedOwner: Owner;

    if (owner.isBusinessOwner) {
      formattedOwner = {
        businessName: owner.businessName,
        ownershipPercent: owner.ownership
      };
    } else {
      formattedOwner = {
        firstName: owner.firstName,
        lastName: owner.lastName,
        title: owner.title,
        ownershipPercent: owner.ownership
      };
    }

    formattedOwners.push(formattedOwner);
  });
  return formattedOwners;
}

export function getPersonalGuarantor(contactInformation: IDynamicObj): PersonalGuarantor[] {
  const personalGuarantors = contactInformation?.personalGuarantors as any;
  const formattedPersonalGuarantors: PersonalGuarantor[] = [];

  personalGuarantors?.forEach((personalGuarantor: any) => {
    const formattedPG: PersonalGuarantor = {
      firstName: personalGuarantor.firstName,
      lastName: personalGuarantor.lastName,
      phone: personalGuarantor?.phoneNum ? formatPhoneNumber(removeSymbols(personalGuarantor?.phoneNum)) : undefined,
      email: personalGuarantor.email
    };

    formattedPersonalGuarantors.push(formattedPG);
  });

  return formattedPersonalGuarantors;
}

export const getFordVehiclePurchaseLocation = (formData: IDynamicObj): string => {
  if (formData.selectedDealerInfo === 'noPreference') {
    return 'I do not have a preferred Ford Dealership';
  }

  const selectedDealerInfo = formData.selectedDealerInfo as string;
  return selectedDealerInfo;
};

export function getLoans(formData: IDynamicObj) {
  if (formData.doesBusinessHaveLineOfCredit === 'Yes') {
    const loans = formData.loans as any;
    const formattedLoans: Loan[] = [];

    loans.forEach((loan: any) => {
      const formattedLoan: Loan = {
        balance: toNumber(removeSymbols(loan.balance)),
        limit: toNumber(removeSymbols(loan.limit)),
        maturityDate: getFormattedDate(loan.maturity),
        lender: loan.lender
      };
      formattedLoans.push(formattedLoan);
    });

    return formattedLoans;
  }
  return [];
}

export function getLocalDate() {
  return new Date().toISOString().split('T')[0];
}

export function attachNestedDocuments(formToSubmit: FormData, statements: File[]) {
  if (statements !== undefined) {
    for (const file of statements) {
      formToSubmit.append('attachments', file);
    }
  }
}

export const getRequestPath = (region: string) => {
  if (region === 'ca') {
    return `/v1/cloc/ca`;
  }
  return `/v1/cloc/us`;
};
