import { IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import Input from '../../atoms/input';
import Select from '../../atoms/select';
import Text from '../../atoms/text';
import { customValidations } from './businessInfo';
import styles from './businessInfo.module.scss';

const AEM_PATH = getAEMPath('businessInformation');

interface PrimaryAddressProps {
  changeHandler: (o: IDynamicObj) => void;
  formInComplete: boolean;
  defaultValues: IDynamicObj;
  region: string;
  stateList: { value: string; label: string }[];
}

const PrimaryAddress = ({ changeHandler, formInComplete, defaultValues, region, stateList }: PrimaryAddressProps) => {
  return (
    <div className={styles.section}>
      <Text name="legalAddress" />
      <div className={styles.form}>
        <Input
          type="text"
          name="street"
          required
          changeHandler={changeHandler}
          validate={formInComplete}
          defaultValue={defaultValues.street as string}
          dataTestId="street"
          showBlankSpaceValidation
        />
        <Input
          type="text"
          name="city"
          required
          changeHandler={changeHandler}
          dataTestId="city"
          validate={formInComplete}
          defaultValue={defaultValues.city as string}
          showBlankSpaceValidation
        />

        {region === 'us' ? (
          <>
            <Select
              name="state"
              options={stateList}
              changeHandler={changeHandler}
              defaultValue={defaultValues.state}
              validate={formInComplete}
              required
              pagePath={AEM_PATH}
            />
            <Input
              type="text"
              name="zip"
              required
              changeHandler={changeHandler}
              customValidation={customValidations.zipCode}
              validate={formInComplete}
              defaultValue={defaultValues.zip as string}
              dataTestId="zipInput"
              inputTypeFormat="zip"
              customErrorKeys={['invalid']}
            />
          </>
        ) : (
          <>
            <Select
              name="province"
              options={stateList}
              changeHandler={changeHandler}
              defaultValue={defaultValues.province}
              validate={formInComplete}
              required
              pagePath={AEM_PATH}
            />
            <Input
              type="text"
              name="postalCode"
              required
              changeHandler={changeHandler}
              customValidation={customValidations.postalCode}
              validate={formInComplete}
              defaultValue={defaultValues.postalCode as string}
              dataTestId="postalInput"
              customErrorKeys={['invalid']}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PrimaryAddress;
