import { useEffect } from 'react';

import { loadLink } from '../jsUtils';

const GlobalScripts = () => {
  useEffect(() => {
    if (process.env.GOOGLE_FONTS_API) {
      loadLink({
        id: 'font-google-api',
        url: process.env.GOOGLE_FONTS_API,
        rel: 'preconnect'
      });
    }
    if (process.env.CXC_ASSETS) {
      loadLink({
        id: 'cxc-assets-ford',
        url: process.env.CXC_ASSETS,
        rel: 'preconnect'
      });
    }
  }, []);

  return null;
};

export default GlobalScripts;
