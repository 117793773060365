import { useState } from 'react';

import useClocStore from '../store';
import { IClocState } from '../store/types';
import useFetchHook from '../utils/useFetchHook';
import { americanStates, canadianProvinces, canadianProvincesFr } from './Regions';

export interface IDealerHook {
  fireAPIAEM?: any;
  fireAPI: () => void;
  apiState: { loading: boolean; data: any; error: any };
}

const API_PATHS: Record<string, string> = {
  en_us: process.env.AEM_STATE_PATH_EN_US as string,
  en_ca: process.env.AEM_STATE_PATH_EN_CA as string,
  fr_ca: process.env.AEM_STATE_PATH_FR_CA as string
};

const StatesData: any = {
  en_us: americanStates,
  en_ca: canadianProvinces,
  fr_ca: canadianProvincesFr
};

const useStateApi = (): IDealerHook => {
  const region = useClocStore((state) => (state as IClocState).region);
  const locale = useClocStore((state) => (state as IClocState).locale);

  const apiPathKey = `${locale}_${region}`;
  const apiPath = API_PATHS[apiPathKey];
  const path = `${process.env.AEM_PUBLISHER_URL}${apiPath}`;

  const fireAPIInternal = useFetchHook();

  const [apiState, setApiState] = useState({
    loading: false,
    data: null,
    error: null
  });

  const parseAPIData = (data: any) => {
    return data?.data?.staticContentModelList.items[0].content.states;
  };

  /**
   * Get state list from AEM
   * AEM Content fragment not ready. TBD in future
   */
  const fireAPIAEM = async () => {
    setApiState({
      loading: true,
      data: null,
      error: null
    });
    const apiRes = await fireAPIInternal({ path });
    setApiState({
      loading: false,
      data: parseAPIData(apiRes),
      error: null
    });
  };

  const fireAPI = () => {
    setApiState({
      loading: false,
      data: StatesData[`${locale}_${region}`],
      error: null
    });
  };

  return { fireAPIAEM, fireAPI, apiState };
};

export default useStateApi;
