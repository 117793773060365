import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect } from 'react';

import useClocStore from '../../../store';
import { getAEMPath, getFormSteps } from '../../../utils/jsUtils';
import Text from '../../atoms/text';
import FormWrapper from '../../molecules/formController';
import styles from './colcForm.module.scss';

const ClocForm = () => {
  const updateRootPage = useClocStore((state) => state.updateRootPage);
  const updateValidationStateOnReload = useClocStore((state) => state.updateValidationStateOnReload);
  const region = useClocStore((state) => state.region);

  const { steps } = getFormSteps(region);

  const isAuthorView = AuthoringUtils.isInEditor();

  useEffect(() => {
    updateRootPage('cloc');
    updateValidationStateOnReload();
  }, []);

  return (
    <div data-testid="clocForm">
      <Text name="main-heading" />
      <Text
        className={styles.mainPara}
        name="main-para"
      />
      <FormWrapper
        forms={steps}
        isAuthorView={isAuthorView}
        aemPath={getAEMPath('cloc')}
      />
    </div>
  );
};

export default ClocForm;
