import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableSingleSelectInput } from '@fcamna/aem-library';
import { SingleSelectInput } from '@fcamna/react-library';
import { useEffect, useRef, useState } from 'react';

import { useModel } from '../../../utils/aem/aemHOC';
import styles from './select.module.scss';

/**
 * Select options[] -  { label, description(optional) }
 * label act as value as well as dropdown display
 * form needs value for validations, so mapping is done
 * description is optional.
 */
export interface SelectProps {
  name: string;
  options?: { value: string; label: string }[];
  defaultValue?: any;
  changeHandler?: (o: Record<string, string | number | boolean>) => void;
  validate: boolean;
  required?: boolean;
  pagePath?: string;
}

/**
 * custom validation is not implemented because select is can be required or not required.
 * BUG: In select comp, input.value is coming as blank, so using val here instead of html elem.
 */
const checkValidityInput = (val: string, required: boolean) => {
  if (!required) {
    return false;
  }

  return !val;
};

/**
 * helperText used as errorMessage for consistency with other atoms, keeping form interface same.
 */
const Select = ({ name, options = [], defaultValue = '', changeHandler, validate, required = false, pagePath = '' }: SelectProps) => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const { parsedModel } = useModel({ pagePath: pagePath });

  const selectDivRef = useRef<HTMLDivElement>(null);

  const [isInvalid, setIsInvalid] = useState(false);
  const [val, setVal] = useState(defaultValue);

  const triggerValidation = (v: string) => {
    const invalid = checkValidityInput(v, required);
    setIsInvalid(invalid);
  };

  const onChangeHandler = ({ label, selectOptions }: any) => {
    const idx = selectOptions.findIndex((o: any) => o.label === label);
    const newVal = options.length ? selectOptions[idx].value : selectOptions[idx].label;
    setVal(newVal);
    triggerValidation(label);
    changeHandler?.({ name, value: newVal, isInvalid });
  };

  useEffect(() => {
    if (validate) {
      triggerValidation(defaultValue);
    }
  }, [validate]);

  if (isAuthorView) {
    return <EditableSingleSelectInput name={name} />;
  }
  if (!parsedModel?.[`singleselectinput_${name}`]) {
    return null;
  }

  const { label, items, placeholder, errorMessage, helperText } = parsedModel[`singleselectinput_${name}`];

  if (options.length < 1) {
    items?.map((item: Record<string, string | number>, index: number) => {
      item.value = String(index);
    });
  }

  const selectOptions = options.length > 0 ? options : items;
  const key = options.length > 0 ? 'value' : 'label';

  const defaultSelected = selectOptions.filter((o: any) => o[key] === val)[0]?.label;

  if (!selectOptions?.length) {
    return null;
  }

  return (
    <div
      ref={selectDivRef}
      className={styles.select}>
      <SingleSelectInput
        labelProp={{ label: label }}
        data-testid={name}
        options={selectOptions}
        placeholder={placeholder || ''}
        errorMessage={isInvalid ? errorMessage : ''}
        onOptionSelect={({ label }: any) => onChangeHandler({ label, selectOptions })}
        selected={defaultSelected}
        helperText={helperText}
      />
    </div>
  );
};

export default Select;
