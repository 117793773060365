/* eslint-disable */
import * as React from 'react';

import { FordProLightProgressTracker } from './FordPro.theme';
import { ProgressStep } from './progressStep';
import { ProgressTrackerProps, ProgressTrackerStepProps } from './types';

const { ProgressTrackerContainer } = FordProLightProgressTracker;

export const ProgressTracker = ({
  activeStep = 1,
  type = 'simple',
  className = '',
  children,
  trackerVisible = true
}: ProgressTrackerProps) => {
  const disabled = false;
  const orientation = 'horizontal';
  let activeStepContent = null;
  // To get total number of steps
  let total = 0;
  let stepContentProps: any = null;
  const steps = React.Children.map(children, (child: any, index: number) => {
    if (child && React.isValidElement(child) && child.type === ProgressStep) {
      const childEle = child.props as ProgressTrackerStepProps;
      total++;
      // assign active step content
      if (activeStep === index + 1) {
        stepContentProps = childEle;
      }

      return React.cloneElement(child, {
        ...{
          _stepIndex: index + 1,
          activeIndex: activeStep,
          completed: childEle.completed ?? index + 1 < activeStep,
          markerText: type === 'progress' ? `${index + 1}` : childEle.markerText
        }
      });
    }
    return null;
  });

  // To render active step content for small device screen sizes
  if (stepContentProps) {
    const stepContentClassNames = ['stepContent'];

    if (stepContentProps.error) {
      stepContentClassNames.push('is-error');
    }

    if (disabled || stepContentProps.disabled) {
      stepContentClassNames.push('is-disable');
    } else if (stepContentProps.onClick) {
      stepContentClassNames.push('pointer');
    }

    if (stepContentProps.className) {
      stepContentClassNames.push(stepContentProps.className);
    }

    activeStepContent = (
      <button
        type="button"
        className={stepContentClassNames.join(' ')}
        onClick={(event: any) =>
          stepContentProps.onClick && !disabled && !stepContentProps.disabled && stepContentProps.onClick(event)
        }
        disabled={disabled || stepContentProps.disabled}
      >
        <div className="progress-text">
          <h4 className="progress-title">{stepContentProps.label}</h4>
          {trackerVisible && (
            <>
              <span className="tracker-text">{`Step ${activeStep} of ${total}`}</span>
              <br />
            </>
          )}
          {stepContentProps.children}
        </div>
      </button>
    );
  }

  if (!ProgressTrackerContainer) {
    return null;
  }

  return (
    <ProgressTrackerContainer type={type} $orientation={orientation}>
      <ul className={`progress-tracker ${className}`}>{steps}</ul>
      {activeStepContent}
    </ProgressTrackerContainer>
  );
};

ProgressTracker.ProgressStep = ProgressStep;
