import { Model, ModelManager } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useState } from 'react';

import useClocStore from '../../store';
import { getSessionStorage } from '../jsUtils';
import { CustomModelClient } from './CustomModelClient';

const AEMCache: any = {};

const parseModelJSON = (pagePath: string) => {
  const res = ModelManager.modelStore.getData(pagePath);
  const modelParsed = res?.[':items']?.['root']?.[':items']?.['responsivegrid']?.[':items'];
  return modelParsed;
};

// read env vars
const AEM_PATH: Record<string, string | undefined> = {
  en_us: process.env.AEM_BASE_PATH_EN_US,
  en_ca: process.env.AEM_BASE_PATH_EN_CA,
  fr_ca: process.env.AEM_BASE_PATH_FR_CA
};

export const useAEMBasePath = (): string => {
  const locale = useClocStore((state) => state.locale);
  const region = useClocStore((state) => state.region);

  const isOverride = getSessionStorage('AEM_LOCALE') as string;
  if (isOverride) {
    return AEM_PATH[isOverride] || '';
  }

  const key = `${locale}_${region}`;
  const aemBasePath = AEM_PATH[key] || '';
  return aemBasePath;
};

export const useRootModel = (): { model: Model | undefined } => {
  const [model, setModel] = useState<Model | undefined>();
  const basePath = useAEMBasePath();

  useEffect(() => {
    try {
      if (!model && basePath) {
        if (AEMCache[basePath]) {
          setModel(AEMCache[basePath]);
          return;
        }

        ModelManager.initialize({
          path: `${basePath}`,
          modelClient: new CustomModelClient(process.env.AEM_PUBLISHER_URL, process.env.AEM_USER, process.env.AEM_PASS)
        }).then((md: Model) => {
          setModel(md);
          AEMCache[basePath] = md;
        });
      }
    } catch (error) {
      console.log('AEM model failed to load');
    }
  }, [basePath, model]);

  return { model };
};

export const useModel = ({ pagePath = '' }: { pagePath?: string }): { parsedModel?: Record<string, any> } => {
  const basePath = useAEMBasePath();
  const [parsedData, setParsedData] = useState<any>();

  useEffect(() => {
    try {
      setParsedData(parseModelJSON(`${basePath}${pagePath}`));
    } catch (error) {
      console.log('Failed to get model data ', pagePath, error);
    }
  }, []);

  return { parsedModel: parsedData };
};
