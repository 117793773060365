/**
 * UCL component do not have helperText & errorMessage as separate prop, only Select supports both
 * Some inputs need multiple error messages
 * this is generic component to show error messages
 *
 * ISSUE:
 * UCL components have inconsistent error font size & weights, e.g. Radio has 14px-400, Input has 12px-300 etc.
 * we have local overrides to keep CLOC experience consistent
 */
import { fordProIcons } from '@fcamna/shared-icons';

import Text from '../text';
import styles from './error.module.scss';

const { WarningFilled } = fordProIcons;

export interface IFieldError {
  name: string;
  className?: string;
  replacement?: any;
}

const FieldError = ({ name, className, replacement }: IFieldError) => {
  return (
    <div className={styles.errorContainer}>
      <WarningFilled
        width={16}
        height={16}
      />
      <div className={styles.error}>
        <Text
          id={`error-${name}`}
          className={`${className}`}
          name={name}
          bodySize="s"
          bodyWeight="m"
          bodyColor="error"
          replacements={replacement}
        />
      </div>
    </div>
  );
};

export default FieldError;
