type LogType = 'info' | 'warn' | 'error';

interface LogMessage {
  message: string;
}

const baseUrl = process.env.CLOC_SERVICE_URL;
const apiPath = `${baseUrl}/v1/log`;

async function logToApi(logMessage: LogMessage, logType: LogType): Promise<void> {
  return; //stopped temporary from logging to api
  try {
    const url = `${apiPath}?logType=${logType}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: logMessage.message
      })
    });

    if (!response.ok) {
      throw new Error(`Failed to log message: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error logging message:', error);
  }
}

const log = {
  info: (message: string) => logToApi({ message }, 'info'),
  warn: (message: string) => logToApi({ message }, 'warn'),
  error: (message: string) => logToApi({ message }, 'error')
};

export default log;
