// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hIYRqlTPR4rMS0MOr8up h4{max-width:100px !important;margin:auto !important}.mO77c5lOhhGHeKAtpjvm{margin:48px 0}`, "",{"version":3,"sources":["webpack://./src/components/molecules/stepTracker/stepTracker.module.scss"],"names":[],"mappings":"AAIE,yBACE,0BAAA,CACA,sBAAA,CAIJ,sBACE,aAAA","sourcesContent":["@import '../../../styles/variables.scss';\n@import '../../../styles/mixins.scss';\n\n.step {\n  h4 {\n    max-width: 100px !important;\n    margin: auto !important;\n  }\n}\n\n.stepperWrapper {\n  margin: $spacing-12x 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `hIYRqlTPR4rMS0MOr8up`,
	"stepperWrapper": `mO77c5lOhhGHeKAtpjvm`
};
export default ___CSS_LOADER_EXPORT___;
