import '../../../styles/global.scss';

import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

import useClocStore from '../../../store';
import { AEMRoot } from '../../../utils/aem/aemRoute';
import GlobalLinks from '../../../utils/GlobalLinks/GlobalLinks';
import { getAEMPath, getSessionStorage } from '../../../utils/jsUtils';
import useGlobalConfig from '../../../utils/useGlobalConfig';
import CompLoader from '../../atoms/compLoader';
import { WithAnalyticsScripts } from '../../molecules/Analytics';
import ErrorBoundary from '../../molecules/errorBoundary';
import Layout from '../layout';

// Launch form step in author view directly based on URL
const getAEMPathAuthorView = () => {
  const aemPageName = window?.location?.pathname?.split('/').slice(-1)?.[0]?.replace(/.html/g, '');
  return getAEMPath('', `/${aemPageName}`);
};

const RootComp = ({ path }: { path: string }) => (
  <ErrorBoundary>
    <GlobalLinks />
    <AEMRoot>
      <Layout>
        <CompLoader path={path} />
      </Layout>
    </AEMRoot>
  </ErrorBoundary>
);

const App = () => {
  useGlobalConfig();
  const isAuthorView = AuthoringUtils.isInEditor();

  const rootPage = useClocStore((state) => state.rootPage);

  if (isAuthorView) {
    return <RootComp path={getAEMPathAuthorView()} />;
  }

  if (!rootPage) {
    return '';
  }

  const landingPage = getSessionStorage('CLOC_ROOTPAGE') ?? rootPage ?? 'cloc';

  const clocInvite = new URLSearchParams(window.location.search).get('screener');

  return (
    <WithAnalyticsScripts>
      <RootComp path={clocInvite === 'clocApplication' ? 'cloc' : landingPage} />
    </WithAnalyticsScripts>
  );
};

export default App;
