import { useEffect } from 'react';

import useClocStore from '../../store';
import { IClocState } from '../../store/types';
import { debounce, getLocalStorage, isWindow, setLocalStorage } from '../jsUtils';

let lsKey = '';

const useLocalStorageSync = ({ skipSub = false }: { skipSub?: boolean }) => {
  const restoreClocState = useClocStore((state) => state.restoreClocState);
  const updateRootPage = useClocStore((state) => state.updateRootPage);

  const getDefaultPage = (rg: string) => (rg.split('_').pop() === 'ca' ? 'cloc' : 'screener');

  const loadPrevState = (key: string) => {
    try {
      lsKey = key;

      const lastState: string = getLocalStorage(key) as string;
      const parsedState = lastState ? JSON.parse(lastState) : null;

      if (parsedState) {
        restoreClocState(parsedState);
      } else {
        updateRootPage(getDefaultPage(key));
      }
    } catch (error) {
      updateRootPage(getDefaultPage(key));
    }
  };

  const saveToLS = debounce((obj: IClocState) => {
    const { rootPage, clocFormData, validationStatus } = obj;
    setLocalStorage(lsKey, JSON.stringify({ rootPage, clocFormData, validationStatus }));
  });

  const resetLS = () => {
    // Wait for debounced subs to get over
    setTimeout(() => {
      if (isWindow()) {
        localStorage.removeItem(lsKey);
      }
    }, 1000);
  };

  useEffect(() => {
    if (skipSub) {
      return;
    }

    // subscribe to store updates without causing component rerender
    const unSub = useClocStore.subscribe((state) => {
      try {
        saveToLS(state);
      } catch (error) {
        console.log('Failed to save local state');
      }
    });

    return function cleanup() {
      if (unSub) {
        unSub();
      }
    };
  }, []);

  return { loadPrevState, resetLS };
};

export default useLocalStorageSync;
