import React, { MutableRefObject, useEffect, useRef } from 'react';

import useStateApi from '../../../apis/useStateApi';
import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { isValidBusinessNumber, isValidPostal, isValidUSFedTaxID, isValidUSZip } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import BussinessInfoSection from './businessInfoSection';
import GaragingAddress from './garagingAddressSection';
import PrimaryAddress from './primaryAddressSection';
import { validateSchema } from './validation';

export const customValidations = {
  zipCode: (value: string) => {
    if (!isValidUSZip(value)) {
      return 'invalid';
    }
    return '';
  },
  federalTaxId: (val: string) => {
    if (!isValidUSFedTaxID(val)) {
      return 'invalid';
    }
    return '';
  },
  businessNumber: (val: string) => {
    if (val && !isValidBusinessNumber(val)) {
      return 'invalid';
    }
    return '';
  },
  postalCode: (value: string) => {
    if (!isValidPostal(value)) {
      return 'invalid';
    }
    return '';
  }
};

export default function BusinessInformation(): React.JSX.Element | null {
  useValidationStatus({ key: 'businessInformation', validationFn: validateSchema });

  const updateFormData = useClocStore((state) => state.updateFormData);

  const { fireAPIAEM, apiState } = useStateApi();

  // These values will trigger rerender when updated in store
  const isGarageAddressSame = useClocStore((state) => (state as IClocState).clocFormData.businessInformation.noGarageAddress);
  const isBusinessNumberEnabled = useClocStore((state) => (state as IClocState).clocFormData.businessInformation.noBusinessNumber);
  const isValid = useClocStore((state) => (state as IClocState).clocFormData.businessInformation.isValid);
  const region = useClocStore((state) => (state as IClocState).region);

  // This is one time read on init, no rerender
  const defaultValues: MutableRefObject<IDynamicObj> = useRef((useClocStore.getState() as IClocState).clocFormData.businessInformation);
  const { isVisited } = defaultValues.current;

  const formInComplete = !!(isVisited && !isValid);

  const changeHandler = ({ name, value }: IDynamicObj) => {
    // set isValid true when all field are valid and form completes else keep false.
    updateFormData('businessInformation', (obj: IDynamicObj) => {
      obj[name as string] = value;
    });
    if (isGarageAddressSame && ['street', 'city', 'state', 'zip', 'province', 'postalCode'].includes(name as string)) {
      setGarageSameAsPrimary();
    }
  };

  function setGarageSameAsPrimary() {
    updateFormData('businessInformation', (obj: IDynamicObj) => {
      obj.noGarageAddress = true;
      obj.garageStreet = obj.street;
      obj.garageCity = obj.city;
      if (region === 'us') {
        obj.garageState = obj.state;
        obj.garageZip = obj.zip;
      } else {
        obj.garageProvince = obj.province;
        obj.garagePostalCode = obj.postalCode;
      }
    });
  }

  const garageCheckHandler = (value: number) => {
    const checkBox = value;
    if (checkBox === 2) {
      setGarageSameAsPrimary();
    } else {
      updateFormData('businessInformation', (obj: IDynamicObj) => {
        obj.noGarageAddress = false;
        obj.garageStreet = '';
        obj.garageCity = '';
        if (region === 'us') {
          obj.garageState = '';
          obj.garageZip = '';
        } else {
          obj.garageProvince = '';
          obj.garagePostalCode = '';
        }
      });
    }
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('businessInformation', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }
    fireAPIAEM();
    fireAnalyticsLoad({ pageName: 'businessInfo' });
  }, []);

  if (!apiState.data) {
    return null;
  }

  return (
    <>
      <BussinessInfoSection
        changeHandler={changeHandler}
        formInComplete={formInComplete}
        defaultValues={defaultValues.current}
        region={region}
        stateList={apiState.data}
        isBusinessNumberEnabled={isBusinessNumberEnabled as boolean}
      />

      <PrimaryAddress
        changeHandler={changeHandler}
        formInComplete={formInComplete}
        defaultValues={defaultValues.current}
        region={region}
        stateList={apiState.data}
      />

      <GaragingAddress
        changeHandler={changeHandler}
        formInComplete={formInComplete}
        region={region}
        garageCheckHandler={garageCheckHandler}
        isGarageAddressSame={isGarageAddressSame as boolean}
        stateList={apiState.data}
      />
    </>
  );
}
