import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Divider } from '@fcamna/react-library';
import { useEffect, useState } from 'react';

import useClocStore from '../../../store';
import { getAEMPath } from '../../../utils/jsUtils';
import ButtonWrapper from '../../atoms/button';
import Radio from '../../atoms/radio';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './screener.module.scss';

const AEM_PATH = getAEMPath('screener');

const fireOnCocaRedirect = (buttonType: string) => {
  const COCA_URL = buttonType === 'ApplyNow' ? process.env.COCA_PRO_APPLY_EN_US : process.env.COCA_PRO_EN_US;
  if (COCA_URL) {
    setTimeout(() => {
      window.location.href = COCA_URL;
    }, 500);
  }
};

const Coca = () => {
  const updateRootPage = useClocStore((state) => state.updateRootPage);

  const gotoCloc = () => {
    updateRootPage('cloc');
  };

  return (
    <div
      data-testid="scr-coca"
      className={styles.section}>
      <div>
        <Text name="coca-text" />
        <ButtonWrapper
          className={styles.field}
          name="coca-button"
          as={'a'}
          target="_self"
          onClick={() => fireOnCocaRedirect('LearnMore')}
          directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
        />
      </div>

      <div className={styles.group}>
        <Text name="cloc-text" />
        <ButtonWrapper
          className={styles.field}
          name="cloc-button"
          onClick={gotoCloc}
          directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
        />
      </div>
    </div>
  );
};

const Cloc = () => {
  const updateRootPage = useClocStore((state) => state.updateRootPage);

  const gotoCloc = () => {
    updateRootPage('cloc');
  };

  return (
    <div
      data-testid="scr-cloc"
      className={styles.section}>
      <Text name="cloc-welcome-text" />
      <ButtonWrapper
        className={styles.field}
        name="cloc-entry-button"
        onClick={gotoCloc}
        directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
      />
    </div>
  );
};

const ClocCustomer = () => {
  return (
    <div
      data-testid="scr-cloc-cust"
      className={styles.section}>
      <Text name="cloc-customer-title" />
      <div className={styles.field}>
        <Text name="cloc-customer-para" />
      </div>
      <ButtonWrapper
        className={styles.field}
        name="coca-button-apply"
        as={'a'}
        target="_self"
        onClick={() => fireOnCocaRedirect('ApplyNow')}
        directProps={{ icon: 'caretRight', iconPosition: 'right', size: 'small' }}
      />
    </div>
  );
};

const Screener = () => {
  const RADIO_OPTIONS = ['Yes', 'No'];
  const updateRootPage = useClocStore((state) => state.updateRootPage);
  const homePath = useClocStore((state) => state.homePath);

  const [radioState, setRadioState] = useState<Record<string, string>>({});

  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    if (name === 'hasCloc') {
      setRadioState({ [name as string]: value as string });
    } else {
      setRadioState({ ...radioState, [name as string]: value as string });
    }
  };

  const { hasCloc, hasMoreVehicles, hasMoreAmount } = radioState;

  const isAuthorView = AuthoringUtils.isInEditor();

  const enableEntryPoint = hasCloc === 'No' && hasMoreAmount && hasMoreVehicles;

  const showCloc = enableEntryPoint && (hasMoreVehicles === 'Yes' || hasMoreAmount === 'Yes');

  const showCoca = enableEntryPoint && !showCloc;

  useEffect(() => {
    updateRootPage('screener');
    fireAnalyticsLoad({ pageName: 'screener' });
  }, []);

  const handleReturnHome = () => {
    const link = document.createElement('a');
    link.href = homePath;
    link.target = '_self';
    link.click();
  };

  return (
    <div data-testid="cloc-screener">
      <ButtonWrapper
        name="screen-back"
        onClick={handleReturnHome}
        className={styles.linkBtn}
      />
      <div className={styles.field}>
        <Text
          name="screener-title"
          className={styles.group}
        />
      </div>
      <div
        data-testid="hasCloc"
        className={styles.section}>
        <Radio
          name="hasCloc"
          changeHandler={changeHandler}
          optionsValue={RADIO_OPTIONS}
          aemPath={AEM_PATH}
        />
      </div>

      {(hasCloc === 'No' || isAuthorView) && (
        <>
          <div
            data-testid="hasMoreVehicles"
            className={styles.group}>
            <Radio
              name="hasMoreVehicles"
              changeHandler={changeHandler}
              optionsValue={RADIO_OPTIONS}
              aemPath={AEM_PATH}
            />
          </div>

          <div
            data-testid="hasMoreAmount"
            className={styles.group}>
            <Radio
              name="hasMoreAmount"
              changeHandler={changeHandler}
              optionsValue={RADIO_OPTIONS}
              aemPath={AEM_PATH}
            />
          </div>
        </>
      )}

      {isAuthorView ? (
        <>
          <ClocCustomer />
          <Coca />
          <Cloc />
        </>
      ) : (
        <>
          {(hasCloc === 'Yes' || showCloc || showCoca) && <Divider style={{ marginTop: '48px' }} />}

          {hasCloc === 'Yes' && <ClocCustomer />}

          {showCoca && <Coca />}

          {showCloc && <Cloc />}
        </>
      )}
    </div>
  );
};

export default Screener;
