import Markdown from 'markdown-to-jsx';

import useClocStore from '../../../../store';
import { IClocState } from '../../../../store/types';
import { useModel } from '../../../../utils/aem/aemHOC';
import { getAEMPath, getAEMValue, loadOptimizelyData } from '../../../../utils/jsUtils';
import ButtonWrapper from '../../../atoms/button';
import fordProIcons from '../../../atoms/icon';
import InlineAlert from '../../../atoms/inlineAlert';
import Text from '../../../atoms/text';
import ReviewDetailsCard from '../../../molecules/reviewDetailsCard/reviewDetailsCard';
import styles from '../applicationReview.module.scss';

const { Checkmark: Checkmark48 } = fordProIcons;

const ReviewFinancialStatements = () => {
  const validationStatus = useClocStore((state) => (state as IClocState).validationStatus.financialStatements);
  const financialStatements = useClocStore((state) => (state as IClocState).clocFormData.financialStatements);
  const region = useClocStore((state) => (state as IClocState).region);
  const parsedModel = useModel({ pagePath: getAEMPath('financialStatements') }).parsedModel;
  const updateReviewStep = useClocStore((state) => (state as IClocState).updateReviewStep);

  const stepNum = region === 'ca' ? 4 : 6;

  const { mostRecentDocName, secondRecentDocName, thirdRecentDocName, canadaDocs } = validationStatus;

  function documentAttachedTitleJSX(title: string, showOnlyTitle: boolean) {
    if (showOnlyTitle) {
      return title;
    }
    return (
      <div className={styles.documentTitleValueWrapper}>
        <div className={styles.label}>
          <Markdown>{title}</Markdown>
        </div>
        <span className={styles.checkmark}>
          <Checkmark48 size="s" />
        </span>
      </div>
    );
  }

  function noDocumentAttachedValueJSX(showRequiredIcon: boolean = false) {
    return (
      <div>
        <div className={styles.documentTitleValueWrapper}>
          <div className={styles.text}>
            <Text name="financial-statement-no-document" />
          </div>
          {showRequiredIcon ? <span className={styles.iconCirle}></span> : null}
        </div>
        {showRequiredIcon ? (
          <ButtonWrapper
            className={styles.missingValueInlineAlert}
            name="review-missing-alert"
            onClick={() => updateReviewStep(stepNum)}
          />
        ) : null}
      </div>
    );
  }
  function caDocumentJSX(isDocumentUploaded: boolean) {
    if (isDocumentUploaded) {
      return (
        <>
          <div
            data-testid="ca-fin-stmt-document-wrapper"
            className={styles.documentTitleValueWrapper}>
            <div className={styles.text}>
              <Text name="ca-financial-statement-document-uploaded-title" />
            </div>
            <span className={styles.checkmark}>
              <Checkmark48 size="s" />
            </span>
          </div>
          <div className={styles.text}>
            <Text name="ca-financial-statement-document-uploaded-subtitle" />
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.documentTitleValueWrapper}>
          <div className={styles.text}>
            <Text name="ca-financial-statement-document-missing-notes" />
          </div>
        </div>
      );
    }
  }

  const transformedObj = {
    title: getAEMValue('text_fs-title', parsedModel, 'text'),
    isValid: Boolean(financialStatements.isValid),
    subSections: [
      {
        title: '',
        fields:
          region === 'us'
            ? [
                {
                  title: '',
                  value: <Text name="financial-document-modify-note" />,
                  isOptional: false
                },
                {
                  title: documentAttachedTitleJSX(getAEMValue('text_m-recent-fs-title', parsedModel, 'text'), !mostRecentDocName),
                  value: typeof mostRecentDocName === 'string' ? mostRecentDocName : noDocumentAttachedValueJSX(true),
                  isOptional: false
                },
                {
                  title: documentAttachedTitleJSX(getAEMValue('text_s-recent-fs-title', parsedModel, 'text'), !secondRecentDocName),
                  value: typeof secondRecentDocName === 'string' ? secondRecentDocName : noDocumentAttachedValueJSX(),
                  isOptional: false
                },
                {
                  title: documentAttachedTitleJSX(getAEMValue('text_t-recent-fs-title', parsedModel, 'text'), !thirdRecentDocName),
                  value: typeof thirdRecentDocName === 'string' ? thirdRecentDocName : noDocumentAttachedValueJSX(),
                  isOptional: false
                }
              ]
            : [
                {
                  title: '',
                  value: canadaDocs ? caDocumentJSX(true) : caDocumentJSX(false),
                  isOptional: false
                }
              ]
      }
    ]
  };

  const handleModify = () => {
    loadOptimizelyData('application_review_page_financial_statements_modify_button_6f0s1');
    updateReviewStep(stepNum);
  };

  return (
    <div data-testid="fin-stmt-details-card-test">
      <ReviewDetailsCard
        section={transformedObj}
        modifyHandler={handleModify}
        gridView={false}
        jsxToRender={
          (region === 'us' && !mostRecentDocName) || (region === 'ca' && !canadaDocs) ? (
            <div className={styles.noFileAlert}>
              <InlineAlert name="financial-statement-missing-alert" />
            </div>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default ReviewFinancialStatements;
