import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { ReactNode } from 'react';

const optimizelyClient = process.env.OPTIMIZELY_KEY
  ? createInstance({
      sdkKey: process.env.OPTIMIZELY_KEY,
      datafileOptions: {
        urlTemplate: process.env.OPTIMIZELY_FILE_URL
      }
    })
  : null;

const OptimizelyWrapper = ({ children }: { children: ReactNode }) => {
  if (!optimizelyClient) {
    return children;
  }

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{
        id: 'userId',
        attributes: {
          financial_statements: false
        }
      }}>
      {children}
    </OptimizelyProvider>
  );
};

export default OptimizelyWrapper;
