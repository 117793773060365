import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useState } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import RadioButtonGroup from '../../atoms/radio';
import TextBox from '../../atoms/textArea';
import styles from './fleetInfo.module.scss';

interface RelatedBusinessProps {
  changeHandler: ({ name, value }: IDynamicObj) => void;
  formInComplete: boolean;
  aemPath: string;
}

const RelatedBusinessInfo = ({ changeHandler, formInComplete, aemPath }: RelatedBusinessProps) => {
  const {
    hasRelatedBusiness,
    relatedBusinessName,
    relatedBusinessDescription,
    willRelatedBusinessRequireCredit,
    willRelatedBusinessSubleaseVehicles
  } = useClocStore((state) => (state as IClocState).clocFormData.fleetInformation);
  const RADIO_OPTIONS = ['Yes', 'No'];
  const isAuthorView = AuthoringUtils.isInEditor();
  const [isSwitched, setIsSwitched] = useState(false);

  useEffect(() => {
    if (hasRelatedBusiness === 'No') {
      setIsSwitched(true);
    }
  }, [hasRelatedBusiness]);

  return (
    <div className={styles.form}>
      {hasRelatedBusiness === 'Yes' || isAuthorView ? (
        <>
          <TextBox
            name="relatedBusinessName"
            validate={formInComplete && !isSwitched}
            defaultValue={relatedBusinessName as string}
            changeHandler={changeHandler}
            required
            showBlankSpaceValidation
            customErrorKeys={['required', 'blankspace']}
            aemPath={aemPath}
            dataTestId="relatedBusinessNameInput"
          />

          <TextBox
            name="relatedBusinessDescription"
            validate={formInComplete && !isSwitched}
            defaultValue={relatedBusinessDescription as string}
            changeHandler={changeHandler}
            required
            showBlankSpaceValidation
            customErrorKeys={['required', 'blankspace']}
            aemPath={aemPath}
            dataTestId="relatedBusinessDescriptionInput"
          />
          <div>
            <RadioButtonGroup
              name="willRelatedBusinessRequireCredit"
              validate={formInComplete && !isSwitched}
              defaultValue={willRelatedBusinessRequireCredit as string}
              changeHandler={changeHandler}
              optionsValue={RADIO_OPTIONS}
              required
              aemPath={aemPath}
              dataTestId="willRelatedBusinessRequireCreditRadio"
            />
          </div>
          <div>
            <RadioButtonGroup
              name="willRelatedBusinessSubleaseVehicles"
              validate={formInComplete && !isSwitched}
              defaultValue={willRelatedBusinessSubleaseVehicles as string}
              changeHandler={changeHandler}
              optionsValue={RADIO_OPTIONS}
              required
              aemPath={aemPath}
              dataTestId="willRelatedBusinessSubleaseVehiclesRadio"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RelatedBusinessInfo;
