import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableProgressTracker } from '@fcamna/aem-library';

import { ProgressTracker } from '../../../cxc-components/progressTracker';
import { useModel } from '../../../utils/aem/aemHOC';
import styles from './stepTracker.module.scss';

interface IStepTracker {
  name: string;
  activeStep: number;
  completedSteps: number[];
  disabledSteps: number[];
  stepClickHandler: (num: number) => void;
  aemPath: string;
}

const StepTracker = ({ name, aemPath, activeStep, completedSteps, disabledSteps, stepClickHandler }: IStepTracker) => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const { parsedModel } = useModel({ pagePath: aemPath });

  if (isAuthorView) {
    return <EditableProgressTracker name={name} />;
  }

  const stepHandler = ({ stepIndex }: { stepIndex: number }) => {
    stepClickHandler(stepIndex);
  };

  if (!parsedModel) {
    return null;
  }

  const { items } = parsedModel[`progresstracker_${name}`] || {};

  return (
    <div className={styles.st}>
      <ProgressTracker activeStep={activeStep}>
        {items?.map(({ label, markerText }: any, i: number) => (
          <ProgressTracker.ProgressStep
            key={markerText}
            markerText={markerText}
            label={label}
            completed={completedSteps.includes(i + 1) && activeStep !== i + 1}
            className={styles.step}
            stepClickCallback={disabledSteps.includes(i + 1) ? undefined : stepHandler}
          />
        ))}
      </ProgressTracker>
    </div>
  );
};

export default StepTracker;
