import { IDynamicObj } from '../../../store/types';
import {
  checkFormObjectValidity,
  isValidBusinessNumber,
  isValidDBA,
  isValidPostal,
  isValidString,
  isValidUSFedTaxID,
  isValidUSZip
} from '../../../utils/jsUtils';

const validateUSFields = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { federalTaxId, organizationState, doingBusinessAs, state, zip, garageState, garageZip } = obj;

  validationStatus.federalTaxId = isValidUSFedTaxID(federalTaxId) ? federalTaxId : null;
  validationStatus.organizationState = isValidString(organizationState) ? organizationState : null;
  validationStatus.state = isValidString(state) ? state : null;
  validationStatus.zip = isValidUSZip(zip) ? zip : null;
  validationStatus.garageState = isValidString(garageState) ? garageState : null;
  validationStatus.garageZip = isValidUSZip(garageZip) ? garageZip : null;
  if (doingBusinessAs) {
    validationStatus.doingBusinessAs = isValidDBA(doingBusinessAs) ? doingBusinessAs : null;
  }
};

const validateCanadianFields = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const {
    gstHstRegistration,
    incorporationDate,
    incorporationProvince,
    province,
    postalCode,
    garageProvince,
    garagePostalCode,
    doingBusinessAs,
    noBusinessNumber
  } = obj;

  if (!noBusinessNumber) {
    validationStatus.gstHstRegistration = isValidBusinessNumber(gstHstRegistration) ? gstHstRegistration : null;
  }
  if (incorporationDate) {
    validationStatus.incorporationDate = incorporationDate;
  }
  if (doingBusinessAs) {
    validationStatus.doingBusinessAs = isValidString(doingBusinessAs) ? doingBusinessAs : null;
  }
  validationStatus.incorporationProvince = isValidString(incorporationProvince) ? incorporationProvince : null;
  validationStatus.province = isValidString(province) ? province : null;
  validationStatus.postalCode = isValidPostal(postalCode) ? postalCode : null;
  validationStatus.garageProvince = isValidString(garageProvince) ? garageProvince : null;
  validationStatus.garagePostalCode = isValidPostal(garagePostalCode) ? garagePostalCode : null;
};

const validateCommonFields = (obj: Record<string, any>, validationStatus: IDynamicObj) => {
  const { businessName, website, street, city, garageStreet, garageCity } = obj;

  validationStatus.businessName = isValidString(businessName) ? businessName : null;
  validationStatus.street = isValidString(street) ? street : null;
  validationStatus.city = isValidString(city) ? city : null;
  validationStatus.garageStreet = isValidString(garageStreet) ? garageStreet : null;
  validationStatus.garageCity = isValidString(garageCity) ? garageCity : null;

  if (website) {
    validationStatus.website = isValidString(website) ? website : null;
  }
};

export const validateSchema = (obj: Record<string, any>, region: string) => {
  const validationStatus: IDynamicObj = {};

  validateCommonFields(obj, validationStatus);

  if (region === 'us') {
    validateUSFields(obj, validationStatus);
  } else {
    validateCanadianFields(obj, validationStatus);
  }

  return { isValid: checkFormObjectValidity(validationStatus), validationStatus };
};
