import { EditableRichTextEditor, RichTextEditorProps } from '@fcamna/aem-library';
import { BaseEditable } from '@fcamna/aem-library/src/utils/BaseEditable';

import styles from './text.module.scss';

interface RTEProps {
  className?: string;
  bodySize?: 'xs' | 's' | 'm' | 'l' | 'enlarged';
  bodyWeight?: 'r' | 'm' | 'b';
  bodyColor?: 'default' | 'subtle' | 'subtlest' | 'placeholder' | 'disabled' | 'error';
}

const RichTextEditor = (props: RTEProps & Partial<BaseEditable | RichTextEditorProps>) => {
  const { className = '', bodySize = 'm', bodyWeight = 'm', bodyColor = 'default', ...otherProps } = props;

  // Build the class name string in a more readable way
  const combinedClassName = [
    styles.rtlWrapper,
    styles[`body-${bodySize}`],
    styles[`body-weight-${bodyWeight}`],
    styles[`color-${bodyColor}`],
    className
  ].join(' ');

  return (
    <EditableRichTextEditor
      className={combinedClassName}
      {...otherProps}
    />
  );
};

export default RichTextEditor;
