import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableRadioGroup } from '@fcamna/aem-library';
import { Radio, RadioList, RadioProps } from '@fcamna/react-library';
import { useEffect, useRef, useState } from 'react';

import { useModel } from '../../../utils/aem/aemHOC';
import styles from './radio.module.scss';

export interface RadioButtonGroupProps {
  name: string;
  // label?: string;
  // helperText?: string;
  defaultValue?: string | number;
  horizontal?: boolean;
  changeHandler?: (o: Record<string, string | number | boolean>) => void;
  validate?: boolean;
  required?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  className?: string;
  aemPath?: string;
  optionsValue: Array<string>;
}

/**
 *
 * Custom validation not implemented for radio, isRequired is enough for all use cases
 */
const checkValidityRadio = (elem: NodeListOf<HTMLInputElement>, required: boolean) => {
  if (!required) {
    return false;
  }

  let flag = true;

  elem?.forEach((e: HTMLInputElement) => {
    if (e.checked) {
      flag = false;
    }
  });

  return flag;
};

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const {
    name,
    defaultValue,
    required = false,
    changeHandler,
    horizontal,
    validate,
    disabled = false,
    dataTestId,
    className,
    aemPath = '',
    optionsValue
  } = props;

  const [isInvalid, setIsInvalid] = useState(false);
  const radioDivRef = useRef<HTMLDivElement>(null);
  const { parsedModel } = useModel({ pagePath: aemPath });

  const triggerValidation = () => {
    const radioBtns = radioDivRef.current?.querySelectorAll(`input[type=radio]`) as NodeListOf<HTMLInputElement>;
    const invalid = checkValidityRadio(radioBtns, required);
    setIsInvalid(invalid);
    return invalid;
  };

  const radioChangeHandler = (e: string | number) => {
    triggerValidation();
    changeHandler?.({ name, value: e, isInvalid });
  };

  useEffect(() => {
    if (validate && parsedModel !== undefined) {
      triggerValidation();
    }
  }, [validate, parsedModel]);

  if (AuthoringUtils.isInEditor()) {
    return <EditableRadioGroup name={name} />;
  }

  if (!parsedModel) {
    return null;
  }

  const { items: aemItems, helperText, label, title } = parsedModel[`radiogroup_${name}`] || {};

  const items = aemItems?.map(({ label }: Partial<RadioProps>, index: number) => ({ label, value: optionsValue[index] }));

  return (
    <div ref={radioDivRef}>
      <RadioList
        label={label || title}
        className={`${styles.radio} ${className}`}
        value={defaultValue}
        horizontal={horizontal}
        helperTextProp={{
          defaultProp: {
            helperText: isInvalid ? helperText : ''
          }
        }}
        inputState={isInvalid ? 'error' : undefined}
        dataTestId={dataTestId}
        onChange={radioChangeHandler}>
        {items?.map(({ label, value }: Partial<RadioProps>) => (
          <Radio
            label={label as string}
            value={value as string}
            key={label as string}
            disabled={disabled}
            error={Boolean(isInvalid)}
          />
        ))}
      </RadioList>
    </div>
  );
};

export const RadioButton = ({ label, value, onChange, checked, dataTestId, id }: Partial<RadioProps>) => {
  return (
    <Radio
      id={id}
      label={label as string}
      value={value as string}
      onChange={onChange}
      checked={checked}
      dataTestId={dataTestId}
    />
  );
};

export default RadioButtonGroup;
