import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { useEffect, useRef } from 'react';

import useClocStore, { FileSelection } from '../../../store';
import { IClocState, IDynamicObj, IFinancialStatementsDocs } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import ButtonWrapper from '../../atoms/button';
import FieldError from '../../atoms/fieldError';
import FileUpload from '../../atoms/fileUpload';
import InlineAlert from '../../atoms/inlineAlert';
import RadioButtonGroup from '../../atoms/radio';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import styles from './financialStatements.module.scss';
import { validateSchema } from './validation';

const AEM_PATH = getAEMPath('financialStatements');

// Constants
export const maxFileSize = 10;
const allowedFileTypes = ['application/pdf'];

export const RADIO_OPTIONS = [
  'CPA Reviewed or Audited Statement',
  'Corporate Federal Tax Returns',
  'Internally Prepared Balance Sheet with Profit & Loss Statements and Personal Federal Tax Returns with Schedule C',
  'I do not have the above types of financial statements'
];

// Types
type AlertType =
  | 'mostRecent'
  | 'mostRecentOthers'
  | 'mostRecentdocNotAdded'
  | 'secondRecentdocNotAdded'
  | 'thirdRecentdocNotAdded'
  | 'canadaDocsNotAdded';
interface NoDoumentAlertProps {
  alertType: AlertType;
  isError: boolean;
}

const AuthorableErrors = ({ region }: { region: string }) => (
  <>
    <h1>Authorable Error Messages</h1>
    {region === 'us' ? (
      <>
        {/* individual section documents not added errors */}
        <FieldError name="fs-most-recent-doc-empty" />
        <FieldError name="fs-m-doc-not-added" />
        <FieldError name="fs-s-doc-not-added" />
        <FieldError name="fs-t-doc-not-added" />

        {/* most recent document not added error */}
        <Text name="fs-most-recent-doc-required" />
        <ButtonWrapper
          className={styles.findDealerBtn}
          name="fs-find-dealer"
        />
      </>
    ) : (
      <InlineAlert
        name="canada-docs-missing-alert"
        titleOnly
      />
    )}
  </>
);

const redirectToFindDealer = () => {
  const DEALER_LOCATE = process.env.DEALER_LOCATE_EN_US;
  if (DEALER_LOCATE) {
    setTimeout(() => {
      window.open(DEALER_LOCATE, '_blank', 'noopener,noreferrer');
    }, 500);
  }
};

const NoDoumentAlert = ({ alertType, isError }: NoDoumentAlertProps) => {
  if (!isError || AuthoringUtils.isInEditor()) return null;

  switch (alertType) {
    /* most recent document not added error */
    case 'mostRecent':
      return (
        <div className={styles.dealershipSearchCard}>
          <Text name="fs-most-recent-doc-required" />
          <ButtonWrapper
            onClick={() => redirectToFindDealer()}
            className={styles.findDealerBtn}
            name="fs-find-dealer"
          />
        </div>
      );
    case 'mostRecentOthers':
      return (
        <FieldError
          className={styles.fieldErrors}
          name="fs-most-recent-doc-empty"
        />
      );

    /* individual section documents not added errors */
    case 'mostRecentdocNotAdded':
      return (
        <FieldError
          className={styles.fieldErrors}
          name="fs-m-doc-not-added"
        />
      );
    case 'secondRecentdocNotAdded':
      return (
        <FieldError
          className={styles.fieldErrors}
          name="fs-s-doc-not-added"
        />
      );
    case 'thirdRecentdocNotAdded':
      return (
        <FieldError
          className={styles.fieldErrors}
          name="fs-t-doc-not-added"
        />
      );
    case 'canadaDocsNotAdded':
      return (
        <div className={styles.misingAlert}>
          <InlineAlert
            name="canada-docs-missing-alert"
            titleOnly
            fitWidthToContent
          />
        </div>
      );
    default:
      return null;
  }
};

const FinancialStatements = () => {
  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);
  const updateFinancialStatementsDocs = useClocStore((state) => (state as IClocState).updateFinancialStatementsDocs);

  const region = useClocStore((state) => (state as IClocState).region);
  const isValid = useClocStore((state) => (state as IClocState).clocFormData.financialStatements.isValid);

  const mostRecentDocName = useClocStore((state) => (state as IClocState).clocFormData.financialStatements.mostRecentDocName);
  const secondRecentDocName = useClocStore((state) => (state as IClocState).clocFormData.financialStatements.secondRecentDocName);
  const thirdRecentDocName = useClocStore((state) => (state as IClocState).clocFormData.financialStatements.thirdRecentDocName);

  const financialStatementsDocs = useClocStore((state) => (state as IClocState).financialStatementsDocs);

  const { mostRecentDocs, secondRecentDocs, thirdRecentDocs, canadaDocs } = financialStatementsDocs;

  // This is one time read on init, no rerender
  const defaultFinStmsDocs = useRef(useClocStore.getState().financialStatementsDocs);

  const triggerValidation = useValidationStatus({
    key: 'financialStatements',
    validationFn: validateSchema
  });

  const {
    mostRecentDocs: initialMostRecentDoc,
    secondRecentDocs: initialSecondRecentDoc,
    thirdRecentDocs: initialThirdRecentDoc,
    canadaDocs: initialCanadaDocs
  } = defaultFinStmsDocs.current;

  // This is one time read on init, no rerender
  const defaultValues: any = useRef((useClocStore.getState() as any).clocFormData.financialStatements);
  const { isVisited } = defaultValues.current;

  const formInComplete = !!(isVisited && !isValid);

  const isMostRecentDocAvailable = mostRecentDocName !== RADIO_OPTIONS[3];
  const isSecondRecentDocAvailable = secondRecentDocName !== RADIO_OPTIONS[3];
  const isThirdRecentDocAvailable = thirdRecentDocName !== RADIO_OPTIONS[3];

  const changeHandler = ({ name, value }: Record<string, string | number | boolean | File[]>) => {
    updateFormData('financialStatements', (obj: IDynamicObj) => {
      obj[name as string] = value;
    });
  };

  useEffect(() => {
    if (!isVisited) {
      updateFormData('financialStatements', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }

    fireAnalyticsLoad({ pageName: 'statements' });
  }, []);

  useEffect(() => {
    triggerValidation();
  }, [financialStatementsDocs]);

  const onFileUpload = (files: FileSelection[], docKey: keyof IFinancialStatementsDocs) => {
    updateFinancialStatementsDocs((obj: IFinancialStatementsDocs) => {
      const docFiles = files.map((docFile) => docFile.file);
      obj[docKey] = docFiles;
    });

    defaultFinStmsDocs.current = { ...defaultFinStmsDocs.current, [docKey]: files };
  };

  const mostRecentDocNotAdded = isMostRecentDocAvailable && Boolean(mostRecentDocName) && !mostRecentDocs?.length && formInComplete;
  const secondRecentDocNotAdded =
    isMostRecentDocAvailable && Boolean(secondRecentDocName) && isSecondRecentDocAvailable && !secondRecentDocs?.length && formInComplete;
  const thirdRecentDocNotAdded =
    isMostRecentDocAvailable && Boolean(thirdRecentDocName) && isThirdRecentDocAvailable && !thirdRecentDocs?.length && formInComplete;
  const canadaDocsNotAdded = !canadaDocs?.length && formInComplete;

  return (
    <div>
      <div className={styles.fsInfoSection}>
        <Text name="fs-title" />
        {region === 'us' ? (
          <Text
            className={styles.uploadInfo}
            name="upload-fs-info"
          />
        ) : (
          <Text
            className={styles.uploadInfo}
            name="upload-fs-info-ca"
          />
        )}
      </div>

      {region === 'us' ? (
        <>
          {/* US Version */}
          <div className={styles.uploadInfoSection}>
            <Text name="upload-fs-title" />
          </div>
          <Text name="m-recent-fs-title" />
          <div className={styles.sectionContent}>
            <RadioButtonGroup
              name="mostRecentDocName"
              dataTestId="mostRecentDocName"
              validate={formInComplete}
              changeHandler={changeHandler}
              optionsValue={RADIO_OPTIONS}
              required
              defaultValue={mostRecentDocName as string}
              aemPath={AEM_PATH}
            />
            {isMostRecentDocAvailable ? (
              <FileUpload
                onFileAdded={(files) => onFileUpload(files, 'mostRecentDocs')}
                allowedFileType={allowedFileTypes}
                defaultFiles={initialMostRecentDoc}
                disabled={!mostRecentDocName || !isMostRecentDocAvailable}
                dataTestId="mostRecentDocBtn"
                maxTotalSize={maxFileSize}
                sectionKey="mostRecentDocs"
              />
            ) : null}
            <NoDoumentAlert
              alertType="mostRecent"
              isError={!isMostRecentDocAvailable}
            />
            <NoDoumentAlert
              alertType="mostRecentdocNotAdded"
              isError={mostRecentDocNotAdded}
            />
          </div>

          <Text name="s-recent-fs-title" />
          <div className={styles.sectionContent}>
            <RadioButtonGroup
              name="secondRecentDocName"
              dataTestId="secondRecentDocName"
              validate={formInComplete && isSecondRecentDocAvailable}
              changeHandler={changeHandler}
              defaultValue={secondRecentDocName as string}
              optionsValue={RADIO_OPTIONS}
              disabled={!isMostRecentDocAvailable}
              required
              aemPath={AEM_PATH}
            />
            {isSecondRecentDocAvailable ? (
              <FileUpload
                dataTestId="secondRecentDocBtn"
                onFileAdded={(files) => onFileUpload(files, 'secondRecentDocs')}
                allowedFileType={allowedFileTypes}
                defaultFiles={initialSecondRecentDoc}
                disabled={!secondRecentDocName || !isMostRecentDocAvailable}
                maxTotalSize={maxFileSize}
                sectionKey="secondRecentDocBtn"
              />
            ) : null}
            <NoDoumentAlert
              alertType="mostRecentOthers"
              isError={!isMostRecentDocAvailable}
            />
            <NoDoumentAlert
              alertType="secondRecentdocNotAdded"
              isError={secondRecentDocNotAdded}
            />
          </div>

          <Text name="t-recent-fs-title" />
          <div className={styles.sectionContent}>
            <RadioButtonGroup
              name="thirdRecentDocName"
              dataTestId="thirdRecentDocName"
              validate={formInComplete && isThirdRecentDocAvailable}
              optionsValue={RADIO_OPTIONS}
              changeHandler={changeHandler}
              defaultValue={thirdRecentDocName as string}
              disabled={!isMostRecentDocAvailable}
              required
              aemPath={AEM_PATH}
            />
            {isThirdRecentDocAvailable ? (
              <FileUpload
                dataTestId="thirdRecentDocBtn"
                onFileAdded={(files) => onFileUpload(files, 'thirdRecentDocs')}
                allowedFileType={allowedFileTypes}
                defaultFiles={initialThirdRecentDoc}
                disabled={!thirdRecentDocName || !isMostRecentDocAvailable}
                maxTotalSize={maxFileSize}
                sectionKey="thirdRecentDocBtn"
              />
            ) : null}
            <NoDoumentAlert
              alertType="mostRecentOthers"
              isError={!isMostRecentDocAvailable}
            />
            <NoDoumentAlert
              alertType="thirdRecentdocNotAdded"
              isError={thirdRecentDocNotAdded}
            />
          </div>
        </>
      ) : (
        <>
          {/* Canada Version */}
          <div className={styles.canadaUploadSection}>
            <Text name="upload-fs-title" />
            <Text name="upload-fs-max-size" />
          </div>
          <FileUpload
            onFileAdded={(files) => onFileUpload(files, 'canadaDocs')}
            allowedFileType={allowedFileTypes}
            defaultFiles={initialCanadaDocs}
            dataTestId="canadaDocsBtn"
            dropzone
            maxTotalSize={maxFileSize}
            sectionKey="canadaDocsBtn"
          />
          <NoDoumentAlert
            alertType="canadaDocsNotAdded"
            isError={canadaDocsNotAdded}
          />
        </>
      )}
      {AuthoringUtils.isInEditor() ? <AuthorableErrors region={region} /> : null}
    </div>
  );
};

export default FinancialStatements;
