import { fordProIcons } from '@fcamna/shared-icons';
import * as React from 'react';

import { ProgressTrackerStepProps } from './types';

const { Checkmark: Checkmark48, CancelClose: Close } = fordProIcons;

export const ProgressStep = ({
  _stepIndex,
  completed = false,
  error = false,
  className = '',
  activeIndex,
  markerText = '',
  label,
  stepClickCallback
}: ProgressTrackerStepProps) => {
  const onClick = null;
  const disabled = false;

  const MarkerTag = stepClickCallback ? 'button' : 'span';

  const getErrorClass = (error: boolean, completed: boolean) => {
    if (error) {
      if (completed) {
        return 'is-error completed';
      }
      return 'is-error';
    }
    if (completed) {
      return 'is-complete';
    }
    return '';
  };

  const getDisabledClass = (disabled: boolean, onClick: any) => {
    if (disabled) {
      return 'disabled';
    }
    if (onClick) {
      return 'pointer';
    }
    return '';
  };

  const getClassNames = () => {
    return {
      errorClass: getErrorClass(error, completed),
      activeClass: _stepIndex === activeIndex ? 'is-active' : '',
      disabledClass: getDisabledClass(disabled, onClick),
      clickableClass: stepClickCallback ? 'clickable' : ''
    };
  };

  const getIcon = () => {
    if (completed) {
      if (error) {
        return <Close size="s" />;
      }
      return <Checkmark48 size="s" />;
    }
    return markerText.substring(0, 2);
  };

  const { errorClass, activeClass, disabledClass, clickableClass } = getClassNames();
  const icon = getIcon();

  return (
    <li className={`progress-step ${errorClass} ${activeClass} ${disabledClass} ${className}`}>
      <div className={`progress-marker ${clickableClass}`}>
        <MarkerTag
          className={`indicator ${completed ? '' : 'text'}`}
          aria-label={`${markerText}-${label}`}
          aria-current={_stepIndex === activeIndex}
          onClick={() =>
            stepClickCallback?.({
              stepIndex: _stepIndex ?? 0,
              markerText,
              label: label ?? ''
            })
          }>
          {icon}
        </MarkerTag>
        <span className="connector"></span>
      </div>
      <div className="progress-text mt-4 mb-7">
        <h2
          className="progress-title"
          id={`pt-${label}`}>
          {label}
        </h2>
      </div>
    </li>
  );
};
