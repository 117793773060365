import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableDatePicker } from '@fcamna/aem-library';
import { useEffect, useRef, useState } from 'react';

import { IDynamicObj } from '../../../store/types';
import FieldError from '../fieldError';
import styles from './datePicker.module.scss';

interface DateInputProps {
  value?: object | string;
  selectorStartingYear?: number;
  selectorEndingYear?: number;
  name: string;
  changeHandler: (o: IDynamicObj) => void;
  validate: boolean;
  required?: boolean;
}

const checkValidityInput = (value: object | string, required: boolean) => {
  if (!required) {
    return false;
  }

  return !value;
};

const DateInput = ({
  value = '',
  selectorStartingYear = new Date().getFullYear(),
  selectorEndingYear = new Date().getFullYear() + 100,
  name,
  changeHandler,
  validate,
  required = false
}: DateInputProps) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [dateValue, setDateValue] = useState(value);
  const dateInputContainerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>();
  const isAuthorView = AuthoringUtils.isInEditor();

  const triggerValidation = (value: object | string) => {
    const invalid = checkValidityInput(value, required);
    setIsInvalid(invalid);
  };

  const onChangeHandler = (value: any) => {
    if (value?.year && (value?.year < selectorStartingYear || value?.year > selectorEndingYear)) {
      return;
    }

    setDateValue(value);
    triggerValidation(value);
    changeHandler({ name, value: value, isInvalid });
  };

  useEffect(() => {
    if (dateInputContainerRef.current) dateInputRef.current = dateInputContainerRef.current.querySelectorAll(`input`)[0];
    dateInputRef.current?.setAttribute('name', name);
  }, []);

  useEffect(() => {
    if (validate) {
      triggerValidation(value);
    }
  }, [validate]);

  return (
    <div
      ref={dateInputContainerRef}
      className={styles.input}>
      <EditableDatePicker
        value={dateValue as string}
        name={name}
        data-testid={name}
        onChange={onChangeHandler}
        isInvalid={isInvalid}
        isRequired={required}
        selectorStartingYear={selectorStartingYear}
        selectorEndingYear={selectorEndingYear}
        inputName={name}
        showErrorIcon={false}
      />
      {(isInvalid || isAuthorView) && (
        <FieldError
          className={styles.customError}
          name={name}
        />
      )}
    </div>
  );
};

export default DateInput;
