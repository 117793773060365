import { Checkbox, CheckboxStateValues } from '@fcamna/react-library';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import Input from '../../atoms/input';
import Select from '../../atoms/select';
import Text from '../../atoms/text';
import { customValidations } from './businessInfo';
import styles from './businessInfo.module.scss';

const AEM_PATH = getAEMPath('businessInformation');

interface GaragingAddressProps {
  changeHandler: (o: IDynamicObj) => void;
  garageCheckHandler: (value: number) => void;
  formInComplete: boolean;
  region: string;
  isGarageAddressSame: boolean;
  stateList: { value: string; label: string }[];
}

const GaragingAddress = ({
  changeHandler,
  garageCheckHandler,
  formInComplete,
  region,
  isGarageAddressSame,
  stateList
}: GaragingAddressProps) => {
  const { garageStreet, garageCity, garageState, garageProvince, garageZip, garagePostalCode } = (useClocStore.getState() as IClocState)
    .clocFormData.businessInformation;

  const garaCheckHandle = (value: number) => {
    garageCheckHandler(value);
  };
  return (
    <div className={styles.section}>
      <Text name="vehicleGaragingAddress" />

      <div className={styles.garagingHeading}>
        <Checkbox
          label="Same as Primary Legal Address"
          checkboxValue={isGarageAddressSame ? CheckboxStateValues.CHECKED : CheckboxStateValues.UNCHECKED}
          onChange={(_e, value) => {
            garaCheckHandle(value);
          }}
        />
      </div>

      {!isGarageAddressSame && (
        <div className={styles.form}>
          <Input
            type="text"
            name="garageStreet"
            changeHandler={changeHandler}
            validate={formInComplete}
            required
            dataTestId="garageStreetInput"
            defaultValue={garageStreet as string}
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="garageCity"
            changeHandler={changeHandler}
            validate={formInComplete}
            required
            dataTestId="garageCity"
            defaultValue={garageCity as string}
            showBlankSpaceValidation
          />

          {region === 'us' ? (
            <>
              <Select
                name="garageState"
                options={stateList}
                changeHandler={changeHandler}
                defaultValue={garageState}
                validate={formInComplete}
                required
                pagePath={`${AEM_PATH}`}
              />
              <Input
                type="text"
                name="garageZip"
                changeHandler={changeHandler}
                customValidation={customValidations.zipCode}
                validate={formInComplete}
                required
                dataTestId="garageZip"
                defaultValue={garageZip as string}
                inputTypeFormat="zip"
                customErrorKeys={['invalid']}
              />
            </>
          ) : (
            <>
              <Select
                name="garageProvince"
                options={stateList}
                changeHandler={changeHandler}
                defaultValue={garageProvince}
                validate={formInComplete}
                required
                pagePath={`${AEM_PATH}`}
              />
              <Input
                type="text"
                name="garagePostalCode"
                required
                changeHandler={changeHandler}
                customValidation={customValidations.postalCode}
                validate={formInComplete}
                defaultValue={garagePostalCode as string}
                dataTestId="garagePostalInput"
                customErrorKeys={['invalid']}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GaragingAddress;
