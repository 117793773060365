import { ReactNode, useEffect } from 'react';

import useClocStore from '../../../store';
import { getSessionStorage, loadScript } from '../../../utils/jsUtils';
import OptimizelyWrapper from '../optimizely';
import OneTrustCookies from './onetrustCookies';

export const WithAnalyticsScripts = ({ children }: { children: ReactNode }) => {
  const region = useClocStore((state) => state.region);
  const disableExternalScripts = getSessionStorage('DISABLE_EXTERNAL_SCRIPTS') === 'true';

  useEffect(() => {
    if (disableExternalScripts) {
      return;
    }

    if (process.env.MOUSELFOW) {
      loadScript({ id: 'cloc_mouseflow', url: process.env.MOUSELFOW, className: 'optanon-category-6' });
    }

    if (region === 'us' && process.env.CAPTCHA_SITE_KEY) {
      loadScript({
        id: 'recaptcha',
        url: `https://www.google.com/recaptcha/enterprise.js?render=${process.env.CAPTCHA_SITE_KEY}`
      });
    }
  }, []);

  if (disableExternalScripts) {
    return <>{children}</>;
  }

  return (
    <OneTrustCookies>
      <OptimizelyWrapper>{children}</OptimizelyWrapper>
    </OneTrustCookies>
  );
};
