export interface IFetchHook {
  path: string;
  options?: Record<string, unknown>;
  resHandler?: (param: Response) => unknown;
  errorHandler?: (obj: Record<string, unknown>) => void;
}

const getOptions = (params: any) => {
  const defaults = {
    method: 'GET'
  };

  const newObj = {
    ...defaults,
    ...params
  };

  // header is object, all other fields are plain values, recursive merge
  if (params?.headers) {
    newObj.headers = {
      ...params.headers
    };
  }

  return newObj;
};

const useFetchHook = () => {
  return async ({ path = '', options = {}, resHandler, errorHandler }: IFetchHook) => {
    if (typeof fetch === 'undefined') {
      throw new Error('fetch not defined');
    }

    const fetchOptions = getOptions(options);

    try {
      const res = await fetch(path, fetchOptions);
      if (resHandler) {
        return resHandler(res);
      } else {
        return await res.json();
      }
    } catch (error) {
      if (errorHandler) {
        errorHandler({ path, fetchOptions, error });
      }
    }
  };
};

export default useFetchHook;
